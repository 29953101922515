import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import {
  Button, Backdrop, CircularProgress, Grid,
} from "@mui/material";
import { MathJax } from "better-react-mathjax";
import ReactHtmlParser from 'react-html-parser';
import Latex from "react-latex";
import Header from './../Header/Header';
import Footer from './../Footer/Footer';

import RememberAnswerDialog from './RememberAnswerDialog';

function ExamQuizResult() {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [quiz_key, setQuizKey] = useState(params.quiz_key);

  const [quizResult, setQuizResult] = useState(false);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [action, setAction] = useState('remember');

  const [question, setQuestion] = useState([]);
  useEffect(() => {
    if (quiz_key) {
      fetchQuizResult();
    }
  }, []);

  useEffect(() => {
    if (question.length === undefined && question) {
      // setPreviewModalOpen(true);
    }

  }, [question]);

  /**
   * Fetching data from server.
   */
  const fetchQuizResult = async () => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/frontend/get-exam-quiz-result', { 'quiz_key': quiz_key })
          .then(response => {
            if (response.data.response_code === 200) {
              setQuizResult(response.data.data);
            } else if (response.data.response_code === 401) {

            } else {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  // Product Preview
  const visiblityPreviewModal = (quest, view_action) => {
    setAction(view_action);
    setQuestion(quest);
    setPreviewModalOpen(true);
  };

  /**
    * This function is used to transform string to Latex.
    */
  function transform(node, index) {
    if (node.type === 'tag') {
      return node.data;
    } else {
      return <Latex displayMode={true} trust={true} key={index}>{node.data}</Latex>;
    }
  }
  return (
    <>
      <Header />
      <Backdrop
        sx={{ color: '#015998', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={setLoading}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
      <section id="hero" className="d-flex align-items-center inhead">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center" data-aos="fade-up" data-aos-delay="200">
              {/* <h1>Result</h1> */}
            </div>
          </div>
        </div>
      </section>

      <main id="main result-page" className="result-page-view">
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <ol>
              <li><Link to="#" onClick={() => navigate(-2)} title='Home'>Home</Link></li>
              {(quizResult) && (quizResult.quiz.chapter_name) && (
                <li onClick={() => navigate(-2)}>{quizResult.quiz.chapter_name}</li>
              )}
              <li>Result</li>
            </ol>
          </div>
        </section>

        {quizResult && (
          <>
            <section className="inner-page">
              <div className="container">
                {(quizResult.quiz.chapter_name) ? (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="chapter-name"> {quizResult.quiz.chapter_name}</div>
                    </div>
                  </div>
                ) : ''}

                <div className="row">
                  <div className="col-md-3">
                    <div className="reslutsec resultblue">
                      <i className="bi bi-question-lg"></i>
                      <p>Total Questions</p>
                      <h4>{quizResult.quiz.number_of_questions} </h4>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="reslutsec resultyellow">
                      <i className="bi bi-pencil"></i>
                      <p>Total Answered</p>
                      <h4>{quizResult.quiz.number_of_attempts} </h4>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="reslutsec resultgreen">
                      <i className="bi bi-check-lg"></i>
                      <p>Correct Questions</p>
                      <h4>{quizResult.quiz.correct_answers}</h4>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="reslutsec resultred">
                      <i className="bi bi-x-lg"></i>
                      <p>Wrong Questions</p>
                      <h4>{quizResult.quiz.wrong_answers}</h4>
                    </div>
                  </div>

                </div>
              </div>
              <br />
              <div className="container">
                <div className="row">
                  {quizResult.quiz_questions && (
                    <>
                      {
                        quizResult.quiz_questions.map((row, index) => (
                          <div className="col-md-12" key={index}>
                            <div className={(row.is_correct) ? "rightanswer" : "rightanswer"}>
                              <div className="queststyle res-queststyle" style={{ width: '100%', maxWidth: '100%' }} >
                                <div className='question-number'>{`${(index + 1)}.  `} </div>{` `}
                                <div className='question-title'>
                                  <MathJax>
                                    {row.question_title && (ReactHtmlParser(row.question_title, { transform }))}
                                  </MathJax>
                                </div>
                                {/* <h3>
                                  <MathJax>
                                    {`${(index + 1)} :-  `}
                                    {row.question_title && (ReactHtmlParser(row.question_title, { transform })
                                    )}
                                  </MathJax>
                                </h3> */}
                                {(row.question_image) ? (
                                  <img src={row.question_image} title={row.question_title} alt={row.question_title} className='question-image' />
                                ) : ''
                                }
                                {(() => {
                                  switch (row.question_type) {
                                    case 'radio':
                                      return (
                                        <Grid className="inputform1" container spacing={3}>
                                          {
                                            row.options.map((option, opt_index) => (
                                              <Grid item sm={12} xs={12} lg={6} key={opt_index}>
                                                <Button key={opt_index}
                                                  variant={(row.answer_option_id && (row.answer_option_id === option.id)) ? "contained" : ((row.answer_option_id && (row.correct_option_id === option.id)) ? "contained" : "outlined")}
                                                  color={(row.answer_option_id && (row.answer_option_id === option.id)) ?
                                                    ((row.is_correct) ? "success" : "error")
                                                    :
                                                    (row.answer_option_id && (row.correct_option_id === option.id) ? "success" : "primary")
                                                  }
                                                  disableElevation={true}
                                                  className="quiz-opt"
                                                  sx={{ width: '100% !important', height: '100% !important', justifyContent: 'left', padding: '5px 10px 5px 42px' }}
                                                >
                                                  <span style={{ width: '30px', position: 'absolute', left: 10, fontWeight: 700 }}>
                                                    {(() => {
                                                      switch (opt_index) {
                                                        case 0:
                                                          return '(A) '
                                                        case 1:
                                                          return '(B) '
                                                        case 2:
                                                          return '(C) '
                                                        case 3:
                                                          return '(D) '
                                                        case 4:
                                                          return '(E) '
                                                        default:
                                                          return null
                                                      }
                                                    })()}
                                                  </span>
                                                  {(option.option_image) ? (
                                                    <img src={option.option_image} className="option-image" />
                                                  ) : ''
                                                  }
                                                  <MathJax>
                                                    {option.option_value && (
                                                      <Latex displayMode={true}>{option.option_value && option.option_value.replace("%25", '%').toString()}</Latex>
                                                    )}
                                                  </MathJax>
                                                </Button>
                                              </Grid>
                                            )
                                            )}
                                        </Grid>
                                      )
                                    default:
                                      return null
                                  }
                                })()}
                                <br />
                                <div className="col-md-12 text-center">
                                  {row.answer_option_id ? (
                                    <div className="text-center">
                                      {(row.remember_text || row.remember_image || row.remember_video_link) && (
                                        <Button onClick={() => visiblityPreviewModal(row, 'remember')} size='small' type="button" className="lightbtn">Remember</Button>
                                      )}
                                      {(row.description_text || row.description_image || row.description_video_link) && (
                                        <Button onClick={() => visiblityPreviewModal(row, 'answer')} size='small' className="submitbtn" sx={{ lineHeight: '1.3' }}>View Answer</Button>
                                      )}
                                    </div>
                                  ) : ''}
                                </div>
                              </div>

                            </div>
                          </div>
                        )
                        )
                      }
                    </>
                  )}
                </div>
              </div>
              <RememberAnswerDialog previewModalOpen={previewModalOpen} setPreviewModalOpen={setPreviewModalOpen} question={question} action={action} />
            </section>
          </>
        )}
      </main>
      <Footer />
    </>
  );
}
export default ExamQuizResult;
