import React, { useEffect, useState, SyntheticEvent, useRef } from 'react';
import { Link, useParams, NavLink, useNavigate } from 'react-router-dom';
import Header from './../Header/Header';
import Footer from './../Footer/Footer';
import { Carousel } from 'primereact/carousel';
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";

import * as Yup from 'yup';
import {
  Alert, Backdrop, CircularProgress, Grid,
} from '@mui/material';
import { useMedium } from 'app/contexts/MediumContext';
import axios from 'axios';
// import MediumDialog from './MediumDialog';

function ClassIncludes() {

  const scollToRef = useRef();
  const params = useParams();
  const [class_group_slug, setClassGroupSlug] = useState(params.class_group_slug);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [slug, setSlug] = useState(params.slug);
  const [class_exam_id, setClassExamId] = useState(params.row_id);
  const [class_group, setClassGroup] = useState(false);
  const [classDetail, setClassDetail] = useState(false);
  /**
   * Effect Handler on slug and navigation change 
   */
  useEffect(() => {
    fetchData(slug);
  }, []);

  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/frontend/get-class-group', {
          'class_group_slug': class_group_slug,
        })
          .then(response => {
            if (response.data.response_code === 200) {
              setClassGroup(response.data.data)
            } else if (response.data.response_code === 401) {

            } else {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
        axios.post('/api/frontend/get-class', {
          'slug': slug,
        })
          .then(response => {
            if (response.data.response_code === 200) {
              setClassDetail(response.data.data)
            } else if (response.data.response_code === 401) {

            } else {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <>
      <Header slug={slug} setSlug={setSlug} />

      <Backdrop
        sx={{ color: '#015998', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={setLoading}
      >
        <CircularProgress color="secondary" />
      </Backdrop>

      {/* <MediumDialog open={open} /> */}


      <section id="hero" className="d-flex align-items-center inhead">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center" data-aos="fade-up" data-aos-delay="200">
              {/* <h1>{(classDetail) ? classDetail.class_name : ''} Subject:
                {(selected_subject) ? ` ${_fn_getSelectedSubjectName()}` : " All"}</h1> */}
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <ol>
              <li><Link to="/" title='Home'>Home</Link></li>
              {class_group && (
                <li><Link to={`/${class_group.slug}`} title={class_group.name}>{class_group.name}</Link></li>
              )}
              {classDetail && (
                <li>{(classDetail) ? classDetail.class_name : ''}</li>
              )}
            </ol>
          </div>
        </section>
        {(classDetail) ? (
          <section className="onecol listing-page">
            <div className="container" data-aos="fade-up">
              <Grid container spacing={3}>
                <Grid item sm={6} xs={6} lg={4}>
                  <Link to={`/${class_group_slug}/class/subjects/${classDetail.slug}`} title="Subject Wise">
                    <div className="boximg">
                      <span className='box-title'>Subject Wise</span>
                      <div className="img-box">
                        <img title="Subject Wise" alt="Subject Wise" src={`${process.env.PUBLIC_URL}/assets/frontend/img/jee.jpg`} className='class-thumb' />
                      </div>
                      <p className="lightred">Subject Wise Class Details</p>
                    </div>
                  </Link>
                </Grid>
                {(classDetail.is_test_series === 1 || classDetail.is_test_series === '1') && (
                  <Grid item sm={6} xs={6} lg={4}>
                    <Link to={`/${class_group_slug}/class/test-series/${classDetail.slug}`} title="Test Series">
                      <div className="boximg">
                        <span className='box-title'>Test Series</span>
                        <div className="img-box">
                          <img title="Test Series" alt="Test Series" src={`${process.env.PUBLIC_URL}/assets/frontend/img/jee.jpg`} className='class-thumb' />
                        </div>
                        <p className="lightred">Class Test Series</p>
                      </div>
                    </Link>
                  </Grid>
                )}
                {(classDetail.is_pyp === 1 || classDetail.is_pyp === '1') && (
                  <Grid item sm={6} xs={6} lg={4}>
                    <Link to={`/${class_group_slug}/class/pyps/${classDetail.slug}`} title="Previous Year Paper">
                      <div className="boximg">
                        <span className='box-title'>Previous Year Paper</span>
                        <div className="img-box">
                          <img title="Previous Year Paper" alt="Previous Year Paper" src={`${process.env.PUBLIC_URL}/assets/frontend/img/jee.jpg`} className='class-thumb' />
                        </div>
                        <p className="lightred">Class Previous Year Paper</p>
                      </div>
                    </Link>
                  </Grid>
                )}
              </Grid>
            </div>
          </section>
        ) : ''}

      </main >
      <Footer />
    </>
  );
}
export default ClassIncludes;
