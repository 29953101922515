import { useState, useEffect } from "react";
import { Link, useParams, NavLink, useNavigate } from 'react-router-dom';
import { Breadcrumb, SimpleCard } from "app/components";
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import swal from 'sweetalert';
import {
  Card, CardContent, CardHeader,
  Divider,
  Box,
  Grid,
  Button,
  styled,
  TextField,
  useTheme,
  RadioGroup, FormControlLabel, Radio,
  FormControl, Select, MenuItem,
} from "@mui/material";
import { useMedium } from 'app/contexts/MediumContext';

const Container = styled("div")(({ theme }) => (
  {
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }
));

const ContentBox = styled(Box)(() => ({
  height: '100%',
  padding: '32px',
  position: 'relative',
  // background: 'rgba(0, 0, 0, 0.01)',
}));

// inital login credentials
const initialValues = {
  first_name: '',
  last_name: '',
  mobile_no: '',
  email: '',
  medium_code: '',
  profile_image: '',
  is_otp_verified: '',
  user_from: '',
  is_social_user: '',
  pin_code: '',
  status: '',
  course: '',
  board_id: '',
  class_id: '',
  state_exam_id: '',
  exam_id: '',
};

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('First Name is required!'),
  last_name: Yup.string()
    .required('Last Name is required!'),
  mobile_no: Yup.string()
    .required('Mobile number is required!')
    .min(10, 'Mobile number must be 10 digit length!')
    .max(10, 'Mobile number must be 10 digit length!')
    .matches(/^[6-9]\d{9}$/, { message: "Please enter valid mobile number!", excludeEmptyString: false }),
  email: Yup.string().email('Invalid Email address').required('Email is required!'),
  pin_code: Yup.string()
    .min(6, "Pin Code must be 6 digit!")
    .max(6, "Pin Code must be 6 digit!")
    .required('Pin Code is required!'),
  // board_id: Yup.string().when('course', {
  //   is: (course) => course === 'class',
  //   then: Yup.string()
  //     .required('Board is required!'),
  //   otherwise: Yup.string(),
  // }),
  // class_id: Yup.string().when('course', {
  //   is: (course) => course === 'class',
  //   then: Yup.string()
  //     .required('Class is required!'),
  //   otherwise: Yup.string(),
  // }),
  // state_exam_id: Yup.string().when('course', {
  //   is: (course) => course === 'state-exam',
  //   then: Yup.string()
  //     .required('State is required!'),
  //   otherwise: Yup.string(),
  // }),
  // exam_id: Yup.string().when('course', {
  //   is: (course) => course === 'central-exam' || course === 'state-exam',
  //   then: Yup.string()
  //     .required('Exam is required!'),
  //   otherwise: Yup.string(),
  // }),
});

const UpdateProfile = () => {

  const { user_id } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(initialValues);
  const [mediums, setMediums] = useState(false);
  const [boards, setBoards] = useState(false);
  const [classes, setClasses] = useState(false);
  const [states, setStates] = useState(false);
  const [exams, setExams] = useState(false);
  const [formData, setFormData] = useState(initialValues);
  const { setUserMedium } = useMedium();
  useEffect(() => {
    fetchData();
  }, []);

  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post('/api/frontend/get-mediums')
        .then(response => {
          if (response.data.response_code === 200) {
            setMediums(response.data.data);
          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });

      axios.post('/api/dashboard/user/get-profile-data')
        .then(response => {
          if (response.data.response_code === 200) {
            // setFormData(response.data.data);
            if (response.data.data.user.course === 'class') {
              _fn_get_boards();
              _fn_get_board_classes(response.data.data.user.board_id)
            }
            if (response.data.data.user.course === 'central-exam') {
              _fn_get_centralExams();
            }
            if (response.data.data.user.course === 'state-exam') {
              _fn_get_states();
              _fn_get_state_exams(response.data.data.user.state_exam_id)
            }
            setUser(response.data.data.user);

          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.error(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
    * This function is used to get Chapter list of selected Class and Subjects.
    */
  const _fn_get_boards = async () => {
    setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post('/api/frontend/get-boards')
        .then(response => {
          if (response.data.response_code === 200) {
            setBoards(response.data.data);
          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * This function is used to get Chapter list of selected Class and Subjects.
   * @param {*} class_exam_id 
   */
  const _fn_get_board_classes = async (board_id) => {
    setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post('/api/frontend/get-board-classes', { 'board_id': board_id })
        .then(response => {
          if (response.data.response_code === 200) {
            setClasses(response.data.data);
          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * This function is used to get Chapter list of selected Class and Subjects.
   */
  const _fn_get_centralExams = async () => {
    setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post('/api/frontend/get-central-exams')
        .then(response => {
          if (response.data.response_code === 200) {
            setExams(response.data.data);
          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * This function is used to get Chapter list of selected Class and Subjects.
   */
  const _fn_get_states = async () => {
    setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post('/api/frontend/get-states')
        .then(response => {
          if (response.data.response_code === 200) {
            setStates(response.data.data);
          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * This function is used to get Chapter list of selected Class and Subjects.
   * @param {*} state_id 
   */
  const _fn_get_state_exams = async (state_exam_id) => {
    setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post('/api/frontend/get-state-exams', { 'state_exam_id': state_exam_id })
        .then(response => {
          if (response.data.response_code === 200) {
            setExams(response.data.data);
          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  }


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // const changeHandler = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.files[0] });
  // };

  const handleFormSubmit = (values, errors) => {
    try {
      // axios.get('/sanctum/csrf-cookie').then(response => {
      const uploadFormData = new FormData();
      uploadFormData.append(user_id, user_id)
      for (let iKey in values) {

        if (iKey === 'profile_image_file') {
          uploadFormData.append(iKey, formData[iKey])
        } else {
          uploadFormData.append(iKey, values[iKey])
        }
      }
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      axios.post('/api/dashboard/user/update-profile', uploadFormData, config)
        .then(response => {
          if (response.data.response_code === 200) {
            localStorage.setItem('profile', JSON.stringify({ ...response.data.data }));
            localStorage.setItem('invc-medium', response.data.data.user.medium_code);
            setUserMedium(response.data.data.user.medium_code);
            swal({
              text: response.data.message,
              icon: "success",
              timer: 2000,
            });
            navigate('/dashboard/profile');
          } else {
            swal({
              text: response.data.message,
              icon: "warning",
              timer: 2000,
            });
            errors.setErrors(response.data.errors);
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.error(error);
        });
      // });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <>
      <Container>
        <Box className="breadcrumb">
          <Breadcrumb routeSegments={[{ name: "dashboard", path: "/dashboard" }, { name: "Update Profile" }]} />
        </Box>

        <Card>
          <ContentBox>
            <CardHeader
              title="Update Profile"
              titleTypographyProps={{
                variant: "h4",
                fontSize: '18px',
                fontWeight: '600',
                // textTransform: 'uppercase',
                color: theme.palette.primary.main
              }}
              action={
                <>
                  <Button title="Back" component={Link}
                    to={`/dashboard/profile`}
                    color="primary" variant="outlined" size="small" sx={{ m: .5 }}>
                    Back
                  </Button>
                </>
              }
            />
            <Divider />
            <CardContent>
              <Box width="100%" overflow="auto">
                {user && (
                  <>
                    <Formik
                      onSubmit={(values, errors) => {
                        handleFormSubmit(values, errors);
                      }
                      }
                      enableReinitialize={true}
                      initialValues={user}
                      validationSchema={validationSchema}
                    >
                      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                          <Grid container style={{ padding: '20px' }} spacing={2}>
                            <Grid item sm={6} xs={12}>
                              <label htmlFor="first_name" className='form-label'>
                                <b>First Name <span className="req-star">*</span></b>
                              </label>
                              <TextField
                                fullWidth
                                type="text"
                                name="first_name"
                                label={false}
                                variant="outlined"
                                onBlur={handleBlur}
                                value={values.first_name}
                                onChange={handleChange}
                                helperText={touched.first_name && errors.first_name}
                                error={Boolean(errors.first_name && touched.first_name)}
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, mb: 2 }}
                              />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              <label htmlFor="last_name" className='form-label'>
                                <b>Last Name <span className="req-star">*</span></b>
                              </label>
                              <TextField
                                fullWidth
                                type="text"
                                name="last_name"
                                variant="outlined"
                                onBlur={handleBlur}
                                value={values.last_name}
                                onChange={handleChange}
                                helperText={touched.last_name && errors.last_name}
                                error={Boolean(errors.last_name && touched.last_name)}
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, mb: 2 }}
                              />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              <label htmlFor="email" className='form-label'>
                                <b>Email ID <span className="req-star">*</span></b>
                              </label>
                              <TextField
                                fullWidth
                                disabled
                                type="email"
                                name="email"
                                variant="outlined"
                                onBlur={handleBlur}
                                value={values.email}
                                onChange={handleChange}
                                helperText={touched.email && errors.email}
                                error={Boolean(errors.email && touched.email)}
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, mb: 2 }}
                              />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              <label htmlFor="mobile_no" className='form-label'>
                                <b>Mobile No. <span className="req-star">*</span></b>
                              </label>
                              {/* <PhoneInput
                        country={"in"}
                        value={values.mobile_no}
                        name="mobile_no"
                        onChange={handleChange}
                        onBlur={(e) => handleBlur(e)}
                        defaultMask=".... ... ..."
                        masks={{ in: ".........." }}
                        onlyCountries={["in"]}
                        inputProps={{
                          name: "mobile_no",
                          required: true,
                        }}
                        disableSearchIcon={true}
                        disableDropdown={true}
                        containerClass="h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-transparent border-transparent focus:bg-white"

                        // containerClass="h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-transparent border-transparent focus:bg-white"
                        inputClass="border h-12 text-sm focus:outline-none block w-full bg-gray-100 dark:bg-transparent border-transparent focus:bg-white"
                        inputStyle={{
                          background: "transparent",
                          // border: "1px solid grey",
                          height: "2.70em",
                          width: "100%",
                          outline: "none",

                        }}
                        buttonStyle={{
                          // height: "3em",
                          // background: "transparent",
                          // outline: "none",
                          // border: "none",
                        }}
                        style={{ marginBottom: "22px" }}
                      /> */}
                              <TextField
                                fullWidth
                                disabled
                                type="number"
                                name="mobile_no"
                                // label="Name"
                                variant="outlined"
                                onBlur={handleBlur}
                                value={values.mobile_no}
                                onChange={handleChange}
                                helperText={touched.mobile_no && errors.mobile_no}
                                error={Boolean(errors.mobile_no && touched.mobile_no)}
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, mb: 2 }}
                                inputProps={
                                  { maxLength: 10 }
                                }
                              />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              <label htmlFor="pin_code" className='form-label'>
                                <b>Select Medium <span className="req-star">*</span></b>
                              </label>
                              <FormControl fullWidth sx={{ textAlign: 'left' }}>
                                <Select
                                  required
                                  id="medium-select"
                                  value={values.medium_code}
                                  name="medium_code"
                                  onChange={handleChange}
                                  sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, background: '#FFF' }}
                                >
                                  <MenuItem value="" key="sg">Select Medium</MenuItem>
                                  {mediums && mediums.map((row) =>
                                    <MenuItem value={row.medium_code} key={row.medium_code}>{row.medium_name}</MenuItem>
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              <label htmlFor="pin_code" className='form-label'>
                                <b>Pin Code </b>
                              </label>
                              <TextField
                                fullWidth
                                type="number"
                                name="pin_code"
                                variant="outlined"
                                onBlur={handleBlur}
                                value={values.pin_code}
                                onChange={handleChange}
                                helperText={touched.pin_code && errors.pin_code}
                                error={Boolean(errors.pin_code && touched.pin_code)}
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, mb: 2 }}
                                inputProps={{ min: 6 }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container style={{ padding: '0px 20px' }} spacing={2}>
                            <Grid item sm={6} xs={12} lg={12}>
                              <label htmlFor="course" className='form-label'>
                                <b>Course Category <span className="req-star">*</span></b>
                              </label>
                              <RadioGroup
                                row
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="course"
                                sx={{ mb: 2 }}
                                value={values.course}
                                onChange={(e) => {
                                  handleChange(e)
                                  if (e.target.value === 'class') {
                                    _fn_get_boards();
                                  }
                                  if (e.target.value === 'central-exam') {
                                    _fn_get_centralExams();
                                  }
                                  if (e.target.value === 'state-exam') {
                                    _fn_get_states();
                                  }
                                }}
                              >
                                <FormControlLabel value="class" control={<Radio />} label="Class" />
                                <FormControlLabel value="central-exam" control={<Radio />} label="Central Exam" />
                                <FormControlLabel value="state-exam" control={<Radio />} label="State Exam" />
                              </RadioGroup>
                            </Grid>
                            {(values.course === 'class') ? (
                              <>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <label htmlFor="course" className='form-label'>
                                    <b>Select Board <span className="req-star">*</span></b>
                                  </label>
                                  <FormControl fullWidth sx={{ textAlign: 'left' }}>
                                    <Select
                                      id="class-board-select"
                                      label={false}
                                      value={values.board_id}
                                      name="board_id"
                                      onChange={(e) => {
                                        if (e.target.value) {
                                          _fn_get_board_classes(e.target.value);
                                          handleChange(e)
                                        } else {
                                          setClasses(false);
                                        }
                                      }}
                                      sx={{ background: '#FFF', '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    >
                                      <MenuItem value='' key={'empt'}>Select Board</MenuItem>
                                      {boards && boards.map((row) =>
                                        <MenuItem value={row.id} key={row.id}>{row.name}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <label htmlFor="class" className='form-label'>
                                    <b>Select Class <span className="req-star">*</span></b>
                                  </label>
                                  <FormControl fullWidth sx={{ textAlign: 'left' }}>
                                    <Select
                                      id="class-select"
                                      label={false}
                                      value={values.class_id}
                                      name="class_id"
                                      onChange={handleChange}
                                      sx={{ background: '#FFF', '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    >
                                      <MenuItem value='' key={'empt'}>Select Class</MenuItem>
                                      {classes && classes.map((row) =>
                                        <MenuItem value={row.id} key={row.id}>{row.class_name}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </>
                            ) : ''}
                            {(values.course === 'state-exam') ? (
                              <>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <label htmlFor="state" className='form-label'>
                                    <b>Select State <span className="req-star">*</span></b>
                                  </label>
                                  <FormControl fullWidth sx={{ textAlign: 'left' }}>
                                    <Select
                                      required
                                      displayEmpty
                                      id="state-exam-select"
                                      value={values.state_exam_id}
                                      name="state_exam_id"
                                      onChange={(e) => {
                                        if (e.target.value) {
                                          _fn_get_state_exams(e.target.value);
                                          handleChange(e)
                                        } else {
                                          setExams(false);
                                        }
                                      }}
                                      sx={{ background: '#FFF', '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    >
                                      <MenuItem value='' key={'empt'}>Select State</MenuItem>
                                      {states && states.map((row) =>
                                        <MenuItem value={row.id} key={row.id}>{row.name}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </>
                            ) : ''}
                            {((values.course === 'central-exam') || values.course === 'state-exam') ? (
                              <>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <label htmlFor="exam" className='form-label'>
                                    <b>Select Exam <span className="req-star">*</span></b>
                                  </label>
                                  <FormControl fullWidth sx={{ textAlign: 'left' }}>
                                    <Select
                                      required
                                      displayEmpty
                                      id="exam-select"
                                      value={values.exam_id}
                                      name="exam_id"
                                      onChange={handleChange}
                                      sx={{ background: '#FFF', marginBottom: '10px', '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    >
                                      <MenuItem value='' key={'empt'}>Select Exam</MenuItem>
                                      {exams && exams.map((row) =>
                                        <MenuItem value={row.id} key={row.id}>{row.name}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </>
                            ) : ''}
                          </Grid>
                          <br />
                          <Grid container style={{ padding: '0px 20px' }} spacing={2}>
                            <Grid item sm={6} xs={12}>
                              <label htmlFor="profile_image" className='form-label'>
                                <b>Upload Profile Image</b>
                              </label>
                              {values.profile_image && (
                                <>
                                  <br />
                                  <img src={values.profile_image} style={{ width: '100px' }} alt="Profile Image" />
                                  <br />
                                </>
                              )}
                              <br />
                              <FormControl fullWidth={true} sx={{ mb: 3 }}>
                                <input
                                  id="upload-photo"
                                  name="profile_image_file"
                                  type="file"
                                  onChange={(e) => {
                                    handleChange(e);
                                    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
                                  }
                                  }
                                />
                              </FormControl>
                              {(errors.profile_image_file)
                                && (touched.profile_image_file)
                                && <div style={{ color: '#ff3d57' }}>{`${errors.profile_image_file}`}</div>}
                            </Grid>
                          </Grid>
                          <Grid container style={{ padding: '30px 20px 0px' }} spacing={4}>
                            <Grid item sm={6} xs={12} lg={3}>
                              <LoadingButton
                                fullWidth
                                type="submit"
                                color="primary"
                                loading={loading}
                                variant="contained"
                                sx={{ mb: 2, mt: 3 }}
                              >
                                Update
                              </LoadingButton>
                            </Grid>
                          </Grid>
                          <br />
                        </form>
                      )}
                    </Formik>
                  </>
                )}
              </Box>
            </CardContent>
          </ContentBox>
        </Card>

      </Container>
    </>

  );
};

export default UpdateProfile;
