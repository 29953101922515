import React from 'react';

import {
  Dialog, DialogTitle, List, ListItem, ListItemButton,
  ListItemAvatar, Avatar, ListItemText
} from "@mui/material";
import LanguageIcon from '@mui/icons-material/Language';
import { blue } from '@mui/material/colors';
import { useMedium } from 'app/contexts/MediumContext';

export default function MediumDialog({ open }) {

  const { activeMediums, handleUserMediumChange } = useMedium();

  const handleLanguageChange = (value) => {
    handleUserMediumChange(value);
  };

  const handleClose = () => {
    return false;
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle sx={{ background: '#420e97', color: '#FFF' }}>Select Medium</DialogTitle>
      <List sx={{ pt: 0, padding: '20px 60px 20px 60px' }}>
        {activeMediums && activeMediums.map((medium) => (
          <ListItem disableGutters key={medium.medium_code}>
            <ListItemButton onClick={() => handleLanguageChange(medium.medium_code)} key={medium.medium_code}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: '#600034', color: '#FFFFF' }} >
                  <LanguageIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={medium.medium_name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog >
  )

}