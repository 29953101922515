import { Box, Button, styled } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';

const PageBannerSection = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="adbanner">
        <div className="container">
          <div className="row" data-aosss="zoom-in">
            <div className="col-md-12">
              <a href="#">
                <img src={`${process.env.PUBLIC_URL}/assets/frontend/img/banner.jpg`} alt="" />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PageBannerSection;
