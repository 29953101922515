import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Dialog,
  DialogTitle, DialogContent, DialogActions,
  IconButton,
  Button,
  styled,
} from "@mui/material";
import { MathJax } from "better-react-mathjax";
import CloseIcon from '@mui/icons-material/Close';
import Latex from "react-latex";
import ReactHtmlParser from 'react-html-parser';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    maxWidth: ' 60%',
    width: '890px;'
  },

}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function ImagePreviewDialog({ imagePreviewModalOpen, setImagePreviewModalOpen, imageUrl }) {
  const handleClose = () => {
    setImagePreviewModalOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={imagePreviewModalOpen}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        </BootstrapDialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {(imageUrl) ? (
            <img src={imageUrl} title={'Full Preview'} alt={'Full Preview'} />
          ) : ''
          }

        </DialogContent >
      </BootstrapDialog >
    </div >
  );
}