import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../../auth/authRoles';

const Checkout = Loadable(lazy(() => import('./Checkout')));
const CheckoutOrderSuccess = Loadable(lazy(() => import('./CheckoutOrderSuccess')));


const examsRoutes = [
  // { path: '/dashboard/exam/checkout/:slug', element: <Checkout props={{ selected_step: 0 }} /> },
  // { path: '/dashboard/class/checkout/:slug', element: <Checkout props={{ selected_step: 0 }} /> },
  // { path: '/dashboard/checkout/class/:slug', element: <Checkout props={{ selected_step: 0 }} /> },
  // { path: '/dashboard/checkout/class/:slug/order-summary', element: <Checkout props={{ selected_step: 0 }} /> },
  // // { path: '/product/checkout/shipping-address', element: <Checkout props={{ selected_step: 1 }} /> },
  // { path: '/dashboard/checkout/class/:slug/order-placed', element: <Checkout props={{ selected_step: 1 }} /> },
  // { path: '/dashboard/checkout/class/:slug/order-success/:order_id', element: <CheckoutOrderSuccess props={{ selected_step: 2 }} /> },

  { path: '/dashboard/checkout/:course/:slug', element: <Checkout props={{ selected_step: 0 }} /> },
  { path: '/dashboard/checkout/:course/:slug/order-summary', element: <Checkout props={{ selected_step: 0 }} /> },
  // { path: '/product/checkout/shipping-address', element: <Checkout props={{ selected_step: 1 }} /> },
  { path: '/dashboard/checkout/:course/:slug/order-placed', element: <Checkout props={{ selected_step: 1 }} /> },
  { path: '/dashboard/checkout/:course/:slug/order-success/:order_id', element: <CheckoutOrderSuccess props={{ selected_step: 2 }} /> },

];

export default examsRoutes;
