import React from "react";
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/styled-engine';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import ReactDOM from 'react-dom';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { MathJaxContext } from "better-react-mathjax";

ReactDOM.render(
  // <StyledEngineProvider injectFirst>
  // <React.StrictMode>
  <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
    <CssBaseline />
    <GoogleOAuthProvider clientId="138287499680-7m3hfkgmlmlc916r5p20d184ihtda1je.apps.googleusercontent.com">
      <MathJaxContext>
        <App />
      </MathJaxContext>
    </GoogleOAuthProvider>
  </BrowserRouter>
  // </React.StrictMode>
  ,
  document.getElementById('root')
);

// for IE-11 support un-comment cssVars() and it's import in this file
// and in MatxTheme file

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
