import AuthGuard from 'app/auth/AuthGuard';
import CartProvider from 'app/contexts/CartContext';

// import chartsRoute from 'app/views/charts/ChartsRoute';
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import quizHistoryRoutes from 'app/views/dashboard/QuizHistory/quizHistoryRoutes';
import coursesRoutes from 'app/views/dashboard/Courses/coursesRoutes';
import classesRoutes from 'app/views/dashboard/Classes/classesRoutes';
import examsRoutes from 'app/views/dashboard/Exams/examsRoutes';
import checkoutRoutes from 'app/views/dashboard/Checkout/checkoutRoutes';

import NotFound from 'app/views/frontend/NotFound';

//Front End Routes
import frontendPrivateRoutes from 'app/views/frontend/FrontendPrivateRoutes';
import frontendRoutes from 'app/views/frontend/FrontendRoutes';

import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';

let routes = [];
routes = [
  {
    element: (
      <AuthGuard>
        <CartProvider>
          <MatxLayout />
        </CartProvider>
      </AuthGuard>
    ),
    // Route for admin login
    children: [
      ...dashboardRoutes,
      ...examsRoutes,
      ...coursesRoutes,
      ...classesRoutes,
      ...quizHistoryRoutes,
      ...checkoutRoutes,
    ],
  },
  // ...frontendPrivateRoutes,
  ...frontendRoutes,
  { path: '*', element: <Navigate to="dashboard" /> },
];

export default routes;
