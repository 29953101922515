import React, { useEffect, useState, SyntheticEvent, useRef } from 'react';
import { Link, useParams, NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from './../../../contexts/AuthContext';
import Header from './../Header/Header';
import Footer from './../Footer/Footer';
import { Carousel } from 'primereact/carousel';
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
import swal from 'sweetalert';
import * as Yup from 'yup';
import {
  Alert, Backdrop, CircularProgress,
} from '@mui/material';
import { useMedium } from 'app/contexts/MediumContext';
import axios from 'axios';
import MediumDialog from './MediumDialog';

function TestSeriseDetails() {
  const scollToRef = useRef();
  const params = useParams();
  const navigate = useNavigate();
  const { user, authSignOut, isAuthenticated, setOpenAuth, setIsSignUp } = useAuth();
  const [loading, setLoading] = useState(false);
  const [class_group_slug, setClassGroupSlug] = useState(params.class_group_slug);
  const [class_group, setClassGroup] = useState(false);
  const [slug, setSlug] = useState(params.slug);
  const [class_exam_id, setClassExamId] = useState(params.row_id);
  const [classDetail, setClassDetail] = useState(false);
  const [testSerise, setTestSerise] = useState(false);
  const { userMedium } = useMedium();
  const [open, setOpen] = useState((!userMedium) ? false : true);

  /**
   * Effect Handler on slug and navigation change 
   */
  useEffect(() => {
    if (userMedium) {
      fetchData(slug);
    }
    fetchClassGroup();
  }, []);

  /**
   * Load data on medium change
   */
  useEffect(() => {
    if (userMedium) {
      fetchData(slug);
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [userMedium]);

  /**
   * Fetching data from server.
   */
  const fetchClassGroup = async () => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/frontend/get-class-group', {
          'class_group_slug': class_group_slug,
        })
          .then(response => {
            if (response.data.response_code === 200) {
              setClassGroup(response.data.data)
            } else if (response.data.response_code === 401) {

            } else {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }
  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/frontend/get-class-test-series-details', {
          'slug': slug,
          'medium_code': userMedium,
          'class_exam_id': class_exam_id
        })
          .then(response => {
            if (response.data.response_code === 200) {
              var class_exam_id = response.data.data.classDetail.id;
              setClassExamId(class_exam_id);
              setClassDetail(response.data.data.classDetail)
              setTestSerise(response.data.data.test_series)
            } else if (response.data.response_code === 401) {

            } else {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * This function is used to Create or Start Chapter Quiz.
   * @param {*} chapter_id 
   */
  const _hendelTestSeriesQuizStart = async (test_series_id) => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        var api_url = '/api/frontend/create-class-test-series-quiz';
        if (isAuthenticated) {
          api_url = '/api/frontend/auth/create-class-test-series-quiz';
        }
        axios.post(api_url, {
          'test_series_id': test_series_id,
          'class_exam_id': class_exam_id,
          user_medium: userMedium
        })
          .then(response => {
            if (response.data.response_code === 200) {
              if (response.data.data.quiz_key) {
                navigate(`/class/test-series-quiz/${response.data.data.quiz_key}`);
              }
            } else if (response.data.response_code === 403) {
              // Open Login popup with message
              swal({
                text: response.data.message,
                icon: "info",
                buttons: [
                  'Cancel',
                  'Login'
                ],
                // timer: 2500
              }).then(function (isConfirm) {
                handleOpen(false);
              });
            } else {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * Handle login auth
   * @param {*} authState 
   */
  const handleOpen = (authState) => {
    if (authState) {
      setIsSignUp(true);
    } else {
      setIsSignUp(false);
    }
    setOpenAuth(true);
  }

  return (
    <>
      <Header slug={slug} setSlug={setSlug} />
      <Backdrop
        sx={{ color: '#015998', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={setLoading}
      >
        <CircularProgress color="secondary" />
      </Backdrop>

      <MediumDialog open={open} />

      <section id="hero" className="d-flex align-items-center inhead">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center" data-aos="fade-up" data-aos-delay="200">
              <h1>Test Series</h1>
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <ol>
              <li><Link to="/" title='Home'>Home</Link></li>
              {class_group && (
                <li><Link to={`/${class_group.slug}`} title={class_group.name}>{class_group.name}</Link></li>
              )}
              {classDetail && (
                <li><Link to={`/${class_group.slug}/class/includes/${classDetail.slug}`} title={classDetail.class_name}>{classDetail.class_name}</Link></li>
              )}
              <li>Test Series</li>
            </ol>
          </div>
        </section>

        <section className="inner-page listing-page">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="tabdesign" ref={scollToRef}>
                  <nav>
                    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                      <div className={'nav-link active'}
                        id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home"
                        aria-selected="true"
                      >
                        {classDetail.class_name} : Test Series

                      </div>
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                      {testSerise && (testSerise.length) ? (
                        <ul>
                          {
                            testSerise.map((row, index) => (
                              <li key={index}>
                                <Link onClick={() => _hendelTestSeriesQuizStart(row.id)} to={'#'} title={row.name}>
                                  <span>{row.name}</span>
                                  {row.description}
                                  <i className="bi bi-arrow-right"></i>
                                </Link>
                              </li>
                            )
                            )
                          }
                        </ul>
                      ) : (
                        <>
                          {testSerise && (
                            <Alert severity="error" style={{ textAlign: 'center' }}>No Test Serise found in this Class — check it out!</Alert>
                          )}
                        </>
                      )}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </main >


      <Footer />
    </>
  );
}
export default TestSeriseDetails;
