import React, { useReducer, createContext, useEffect, useContext, useState } from "react";
import axios from 'axios';

const MediumStateContext = createContext({
  userMedium: "en"
});

export function useMedium() {
  return useContext(MediumStateContext);
}

const MediumProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const defaultMedium = window.localStorage.getItem("invc-medium");
  const [userMedium, setUserMedium] = useState(defaultMedium); // || "en"
  const [activeMediums, setActiveMediums] = useState(false);

  /**
  * This function is default effect
  * @author Pavan Ji <dropmail2pavan@gmail.com>
  */
  useEffect(() => {
    fetchActiveMediums();
  }, []);

  /**
   * Fetch cart data.
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const fetchActiveMediums = async () => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/frontend/get-mediums')
          .then(response => {
            if (response.data.response_code === 200) {
              setActiveMediums(response.data.data);
            } else {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * This function is used to change medium value!
   * @param {String} selected 
   */
  const handleUserMediumChange = async (selected) => {
    const newMedium = selected ? selected : '';
    setUserMedium(newMedium);
    window.localStorage.setItem("invc-medium", newMedium);
  }

  /**
   * Rander Medium Provider to handle User medium (Hindi or English)
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  return (
    <MediumStateContext.Provider value={{
      activeMediums,
      userMedium,
      setUserMedium,
      handleUserMediumChange,
    }}>
      {children}
    </MediumStateContext.Provider>

  );
};

export default MediumProvider;