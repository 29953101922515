import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

import MyProfile from './profile/MyProfile';
import UpdateProfile from './profile/UpdateProfile';

const Dashboard = Loadable(lazy(() => import('./Dashboard')));

const dashboardRoutes = [
  { path: '/dashboard', element: <Dashboard />, auth: authRoles.admin },
  { path: '/dashboard/profile', element: <MyProfile />, auth: authRoles.admin },
  { path: '/dashboard/profile/edit', element: <UpdateProfile />, auth: authRoles.admin },


];

export default dashboardRoutes;
