import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Dialog,
  DialogTitle, DialogContent, DialogActions,
  IconButton,
  Button,
  styled,
} from "@mui/material";
import { MathJax } from "better-react-mathjax";
import CloseIcon from '@mui/icons-material/Close';
import Latex from "react-latex";
import ReactHtmlParser from 'react-html-parser';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    maxWidth: ' 80%',
    width: '890%;'
  },

}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function RememberAnswerDialog({ previewModalOpen, setPreviewModalOpen, question, action }) {

  const [showQuestion, setshowQuestion] = useState(false);
  const handleClose = () => {
    setPreviewModalOpen(false);
  };

  /**
     * This function is used to transform string to Latex.
     */
  function transform(node, index) {
    if (node.type === 'tag') {
      return node.data;
    } else {
      return <Latex displayMode={true} trust={true} key={index}>{node.data}</Latex>;
    }
  }
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={previewModalOpen}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        </BootstrapDialogTitle>
        <DialogContent>
          {question && (
            <>
              {/* <div className="container">
                <div className="row"> */}
              {showQuestion && (
                // <div className="col-md-12">
                <div className="queststyle" style={{ width: '100%', maxWidth: '100%' }}>
                  <h3>
                    <MathJax>
                      {question.question_title && (ReactHtmlParser(question.question_title, { transform })
                      )}
                    </MathJax>
                  </h3>
                  {(question.question_image) ? (
                    <img src={question.question_image} title={question.question_title} alt={question.question_title} className='question-image' />
                  ) : ''
                  }
                  {(() => {
                    switch (question.question_type) {
                      case 'radio':
                        return (
                          <div className="inputform">
                            {question.options &&
                              question.options.map((option, opt_index) => (
                                <Button
                                  key={opt_index}
                                  variant={(question.answer_option_id === option.id) ? "contained" : ((question.correct_option_id === option.id) ? "contained" : "outlined")}
                                  color={((question.answer_option_id === option.id)) ? ((question.is_correct) ? "success" : "error") : ((question.correct_option_id === option.id) ? "success" : "primary")}
                                  disableElevation={true}
                                  className="quiz-opt"
                                >
                                  {(option.option_image) ? (
                                    <img src={option.option_image} className='option-image' />
                                  ) : ''
                                  }
                                  <MathJax>
                                    {option.option_value && (
                                      <Latex displayMode={true}>{option.option_value.toString()}</Latex>
                                    )}
                                  </MathJax>
                                </Button>
                              )
                              )}
                          </div>
                        )
                      default:
                        return null
                    }
                  })()}
                </div>
                // </div>
              )}

              {/* <div className="col-md-12"> */}
              {(action === 'remember') && (
                // <div className="container">
                <div className="queststyle rightanswer" style={{ width: '100%' }}>
                  <h3>Remember</h3>
                  <p>
                    <MathJax>
                      {question.remember_text && (ReactHtmlParser(question.remember_text, { transform })
                      )}
                    </MathJax>
                  </p>
                  <p>
                    {question.remember_image && (
                      <img className='ans-desc-image' src={question.remember_image} />
                    )}
                  </p>
                  <p>
                    {question.remember_video_link && (
                      <Button target='_blank;' href={question.remember_video_link} variant="contained" color="success">Youtube Link</Button>
                    )}
                  </p>
                </div>
                // </div>
              )}
              {(action === 'answer') && (
                // <div className="container">
                <div className="queststyle rightanswer" style={{ width: '100%' }}>
                  <h3>Answer</h3>
                  <p>
                    <MathJax>
                      {question.description_text && (ReactHtmlParser(question.description_text, { transform })
                      )}
                    </MathJax>
                  </p>
                  <p>
                    {question.description_image && (
                      <img className='ans-desc-image' src={question.description_image} />
                    )}
                  </p>
                  <p>
                    {question.description_video_link && (
                      <Button target='_blank;' href={question.description_video_link} variant="contained" color="success">Youtube Link</Button>
                    )}
                  </p>
                </div>
                // </div>
              )}
              {/* </div> */}
              {/* </div>
    </div> */}
            </>
          )
          }
        </DialogContent >
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions> */}
      </BootstrapDialog >
    </div >
  );
}