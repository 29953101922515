import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Header from './../Header/Header';
import Footer from './../Footer/Footer';
import { Grid } from '@mui/material';

import PageBannerSection from './PageBannerSection';

import CentralExamSection from './CentralExamSection';
import StateGroupsExamSection from './StateGroupsExamSection';

import EntranceExamSection from './EntranceExamSection';

import CompetitiveExamSection from './CompetitiveExamSection';



import SchoolClassSection from './SchoolClassSection';

import OurTeams from './OurTeams';
import Testimonials from './Testimonials';

const HomePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [accessToken, setAccessToken] = useState((localStorage.getItem('accessToken')) ? localStorage.getItem('accessToken') : false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {

    } else {
      // setOpen(true);
    }
  }, [location]);

  return (
    <>
      <Header />
      <main id="main">

        <PageBannerSection />

        <CentralExamSection />

        {/* <EntranceExamSection />
        <CompetitiveExamSection /> */}
        <section className="onecol">
          <div className="container aos-init aos-animate" data-aos="fade-up">
            <div className="row">
              <div className="col-md-12">
                <Link to={"/central-exams/upsc"} title='UPSC'>
                  <div className="boximg">
                    <span className="box-title">UPSC</span>
                    <div className="img-box">
                      <img className="class-thumb" title="UPSC" alt="UPSC" src={`${process.env.PUBLIC_URL}/assets/frontend/img/UPSC_NEW.jpg`} />
                    </div>
                    <p className="lightgreen">Start your IAS preparation today</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="onecol">
          <div className="container" data-aos="fade-up">
            <Grid container spacing={3}>
              <Grid item sm={6} xs={6} lg={6}>
                {/* /kindergarten */}
                {/* <Link to={"#"}> */}
                <div className="boximg" style={{ opacity: '.5' }}>
                  <span className='box-title'> KG</span>
                  <div className="img-box">
                    <img src={`${process.env.PUBLIC_URL}/assets/frontend/img/kg.jpg`} className='class-thumb' />
                  </div>
                  {/* , Nursery, KG, LKG and UKG */}
                  <p className="lightpurple">Comprehensive learning for Pre-Nursery</p>
                </div>
                {/* </Link> */}
              </Grid>
              <Grid item sm={6} xs={6} lg={6}>
                <Link to={"/classes"}>
                  <div className="boximg">
                    <span className='box-title'>Class</span>
                    <div className="img-box">
                      <img src={`${process.env.PUBLIC_URL}/assets/frontend/img/class.jpg`} className='class-thumb' />
                    </div>
                    {/* , Secondary , Senior Secondary School */}
                    <p className="lightgrey">Comprehensive learning for Primary, Middle</p>
                  </div>
                </Link>
              </Grid>
            </Grid>
          </div>
        </section>

        <OurTeams />

        {/* <Testimonials /> */}
      </main>
      <Footer />
    </>
  );
};

export default HomePage;
