import React, { useEffect, useState } from 'react';

import axios from 'axios';
import OwlCarousel from 'react-owl-carousel';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { useNavigate, Link } from 'react-router-dom';

const OurTeams = () => {
  const navigate = useNavigate();

  const options = {
    nav: false,
    dots: true,
    autoplay: true,
    loop: true,
    // padding: 20,
    margin: 20,
    responsiveClass: true,
    // navText: ['<i class="ion-ios-arrow-left"></i>', '<i class="ion-ios-arrow-right"></i>'],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
        dots: true,
      },
      1000: {
        items: 5,
      }
    },
  };
  return (
    <>
      <section className="team">
        <div className="container" data-aos="fade-up">

          <div className="section-title">
            <h2>Our Team</h2>
          </div>
          <div className="regular slider">
            <OwlCarousel className='carousel_slider owl-carousel owl-theme'
              {...options}>
              <div className="teamslide"><img src={`${process.env.PUBLIC_URL}/assets/frontend/img/team/Academic-Head-India.jpg`} className=" img-fluid" alt="" />
                <h4>Ravi Kumar</h4>
                <p>Academic Head(India)</p>
              </div>
              {/* <div className="teamslide"><img src={`${process.env.PUBLIC_URL}/assets/frontend/img/team/Bihar-Acdmic-Head.jpg`} className="img-fluid" alt="" />
                <h4>Bipin Yadav</h4>
                <p>Academic Head(Bihar)</p>
              </div> */}
              <div className="teamslide"><img src={`${process.env.PUBLIC_URL}/assets/frontend/img/team/CMO.jpg`} className="img-fluid" alt="" />
                <h4>Ashwani Dixit</h4>
                <p>CMO</p>
              </div>
              <div className="teamslide"><img src={`${process.env.PUBLIC_URL}/assets/frontend/img/team/HEAD-OF-HR.jpg`} className="img-fluid" alt="" />
                <h4>Meera Bisht</h4>
                <p>Head of HR</p>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurTeams;
