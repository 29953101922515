import React, { useEffect, useState } from 'react';

import { Box, Button, styled, Grid } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';

const CentralExamSection = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState(false);
  useEffect(() => {
    // if (row_id !== params.row_id) {
    //   setRowId(params.row_id);
    // } else {
    //   // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    //   fetchData(slug);
    // }
    fetchData()
  }, []);
  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/frontend/get-home-central-exam-lists')
          .then(response => {
            if (response.data.response_code === 200) {
              setRows(response.data.data);
            } else if (response.data.response_code === 401) {

            } else {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  const examLayoutUrl = (row) => {
    var url = '';
    if (row.is_test_series || row.is_pyp) {
      url = `/${row.exam_category_slug}/exam/includes/${row.slug}`;
    } else {
      url = `/${row.exam_category_slug}/exam/subjects/${row.slug}`;
    }
    return url;
  }

  return (
    <>
      {rows && (
        <section className="onecol home-page-section">
          <div className="container" data-aos="fade-up">
            {/* <div className="row"> */}
            <Grid container spacing={3}>

              {
                rows.map((row, index) => (
                  <Grid item sm={6} xs={6} lg={6} key={index}>
                    {/* // <div className="col-md-5" key={index}> */}
                    <Link to={examLayoutUrl(row)} title={row.name}>
                      <div className="boximg">
                        <span className='box-title'>{row.name}</span>
                        <div className="img-box">
                          {(row.thumb_nail_image) ? (
                            <img src={row.thumb_nail_image} title={row.name} alt={row.name} className='class-thumb' />
                          ) : (
                            <img className='class-thumb' title={row.name} alt={row.name} src={`${process.env.PUBLIC_URL}/assets/frontend/img/jee.jpg`} />
                          )}
                        </div>
                        <p className="lightred">{row.description}</p>
                      </div>
                    </Link>
                  </Grid>
                ))
              }
              <Grid item sm={6} xs={6} lg={6}>
                <Link to={"/state-exams/bihar-exams"} title='Bihar Exams'>
                  <div className="boximg">
                    <span className="box-title">Bihar Exams</span>
                    <div className="img-box">
                      <img className="class-thumb" title="Bihar Exams" alt="Bihar Exams" src={`${process.env.PUBLIC_URL}/assets/frontend/img/banner-bg.jpg`} />
                    </div>
                    <p className="lightgreen">Start preparation for Bihar State Exams</p>
                  </div>
                </Link>
              </Grid>
            </Grid>
          </div>
        </section>
      )}
    </>
  );
};

export default CentralExamSection;
