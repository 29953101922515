import useSettings from 'app/hooks/useSettings';
import { Link } from 'react-router-dom';
const MatxLogo = ({ className }) => {
  const { settings } = useSettings();
  const theme = settings.themes[settings.activeTheme];

  return (
    <div>
      <Link to='/dashboard'>
        <img src={`${process.env.PUBLIC_URL}/assets/backend/images/logo.png`} height="50px" title={process.env.REACT_APP_NAME} />
      </Link>
    </div>
  );
};

export default MatxLogo;
