import React from "react";

// Exams Routes
import HomePage from 'app/views/frontend/HomePage/HomePage';
import ExamIncludes from 'app/views/frontend/Exams/ExamIncludes';
import ExamDetails from 'app/views/frontend/Exams/ExamDetails';
import ExamTestSeriseDetails from 'app/views/frontend/Exams/ExamTestSeriseDetails';
import ExamPreviousYearPaperDetails from 'app/views/frontend/Exams/ExamPreviousYearPaperDetails';
import ExamGroupDetails from 'app/views/frontend/Exams/ExamGroupDetails';

// Exams Quiz Routes
import ExamChapterQuiz from 'app/views/frontend/ExamsQuiz/ExamChapterQuiz';
import ExamPracticeTestQuiz from 'app/views/frontend/ExamsQuiz/ExamPracticeTestQuiz';
import ExamPreviousYearQuestionQuiz from 'app/views/frontend/ExamsQuiz/ExamPreviousYearQuestionQuiz';
import ExamTestSeriesQuiz from 'app/views/frontend/ExamsQuiz/ExamTestSeriesQuiz';
import ExamPreviousYearPaperQuiz from 'app/views/frontend/ExamsQuiz/ExamPreviousYearPaperQuiz';

import ExamQuizResult from 'app/views/frontend/ExamsQuiz/ExamQuizResult';




// Class Routes
import Classes from 'app/views/frontend/Classes/Classes';
// import Kindergarten from 'app/views/frontend/Classes/Kindergarten';
import ClassDetails from 'app/views/frontend/Classes/ClassDetails';
import ClassIncludes from 'app/views/frontend/Classes/ClassIncludes';
import TestSeriseDetails from 'app/views/frontend/Classes/TestSeriseDetails';
import PreviousYearPaperDetails from 'app/views/frontend/Classes/PreviousYearPaperDetails';
import ClassGroupDetails from 'app/views/frontend/Classes/ClassGroupDetails';

// Class Quiz Routes
import ChapterQuiz from 'app/views/frontend/ClassesQuiz/ChapterQuiz';
import PracticeTestQuiz from 'app/views/frontend/ClassesQuiz/PracticeTestQuiz';
import PreviousYearQuestionQuiz from 'app/views/frontend/ClassesQuiz/PreviousYearQuestionQuiz';
import TestSeriesQuiz from 'app/views/frontend/ClassesQuiz/TestSeriesQuiz';
import PreviousYearPaperQuiz from 'app/views/frontend/ClassesQuiz/PreviousYearPaperQuiz';
//Comman for all Quiz
import QuizResult from 'app/views/frontend/ClassesQuiz/QuizResult';




import ContactUs from 'app/views/frontend/Pages/ContactUs';
import AboutUs from 'app/views/frontend/Pages/AboutUs';
import TermsOfService from 'app/views/frontend/Pages/TermsOfService';
import PrivacyPolicy from 'app/views/frontend/Pages/PrivacyPolicy';
import RefundCancellation from 'app/views/frontend/Pages/RefundCancellation';

// import PartnerLogin from 'app/views/frontend/Partner/PartnerLogin';
// import PartnerLoginOtp from 'app/views/frontend/Partner/PartnerLoginOtp';
// import PartnerRegister from 'app/views/frontend/Partner/PartnerRegister';

const FrontendRoutes = [

  { path: '/', element: <HomePage /> },

  { path: '/:exam_category_slug/exam/includes/:slug', element: <ExamIncludes /> },
  { path: '/:exam_category_slug/exam/subjects/:slug/', element: <ExamDetails /> },
  { path: '/:exam_category_slug/exam/subjects/:slug/:subject_id', element: <ExamDetails /> },
  { path: '/:exam_category_slug/exam/test-series/:slug/', element: <ExamTestSeriseDetails /> },
  { path: '/:exam_category_slug/exam/pyps/:slug/', element: <ExamPreviousYearPaperDetails /> },

  { path: '/:exam_category_slug/:exam_group_slug', element: <ExamGroupDetails /> },
  { path: '/:exam_category_slug/:exam_group_slug/exam/includes/:slug', element: <ExamIncludes /> },
  { path: '/:exam_category_slug/:exam_group_slug/exam/subjects/:slug', element: <ExamDetails /> },
  { path: '/:exam_category_slug/:exam_group_slug/exam/subjects/:slug/:subject_id', element: <ExamDetails /> },
  { path: '/:exam_category_slug/:exam_group_slug/exam/test-series/:slug', element: <ExamTestSeriseDetails /> },
  { path: '/:exam_category_slug/:exam_group_slug/exam/pyps/:slug', element: <ExamPreviousYearPaperDetails /> },

  // Exam Quiz Route
  { path: '/exam/chapter-quiz/:quiz_key', element: <ExamChapterQuiz /> },
  { path: '/exam/practice-test-quiz/:quiz_key', element: <ExamPracticeTestQuiz /> },
  { path: '/exam/previous-year-question-quiz/:quiz_key', element: <ExamPreviousYearQuestionQuiz /> },
  { path: '/exam/test-series-quiz/:quiz_key', element: <ExamTestSeriesQuiz /> },
  { path: '/exam/previous-year-paper-quiz/:quiz_key', element: <ExamPreviousYearPaperQuiz /> },
  { path: '/exam/quiz-result/:quiz_key', element: <ExamQuizResult /> },




  { path: '/classes/', element: <Classes /> },
  // { path: '/kindergarten/', element: <Kindergarten /> },
  { path: '/:class_group_slug', element: <ClassGroupDetails /> },
  { path: '/:class_group_slug/class/subjects/:slug/', element: <ClassDetails /> },
  { path: '/:class_group_slug/class/subjects/:slug/:subject_id', element: <ClassDetails /> },
  { path: '/:class_group_slug/class/includes/:slug', element: <ClassIncludes /> },
  { path: '/:class_group_slug/class/test-series/:slug/', element: <TestSeriseDetails /> },
  { path: '/:class_group_slug/class/pyps/:slug/', element: <PreviousYearPaperDetails /> },

  // Class Quiz Route
  { path: '/class/chapter-quiz/:quiz_key', element: <ChapterQuiz /> },
  { path: '/class/practice-test-quiz/:quiz_key', element: <PracticeTestQuiz /> },
  { path: '/class/previous-year-question-quiz/:quiz_key', element: <PreviousYearQuestionQuiz /> },
  { path: '/class/test-series-quiz/:quiz_key', element: <TestSeriesQuiz /> },
  { path: '/class/previous-year-paper-quiz/:quiz_key', element: <PreviousYearPaperQuiz /> },
  { path: '/class/quiz-result/:quiz_key', element: <QuizResult /> },

  // Static Page Routes
  { path: '/contact-us', element: <ContactUs /> },
  { path: '/about-us', element: <AboutUs /> },
  { path: '/terms-of-service', element: <TermsOfService /> },
  { path: '/privacy-policy', element: <PrivacyPolicy /> },
  { path: '/refund-cancellation', element: <RefundCancellation /> },



  // { path: '/login', element: <PartnerLogin /> },
  // { path: '/login-otp', element: <PartnerLoginOtp /> },
  // { path: '/partner-register', element: <PartnerRegister /> },
  // { path: '/session/forgot-password', element: <ForgotPassword /> },
  // { path: '/session/404', element: <NotFound /> },
];

export default FrontendRoutes;
