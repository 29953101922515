import React, { useReducer, createContext, useEffect, useContext, ReactNode, useState } from "react";
import axios from 'axios';
import swal from 'sweetalert';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Backdrop, CircularProgress, Dialog, IconButton, Button, Select, FormControl, InputLabel, MenuItem, Grid
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookLogin from 'react-facebook-login';
import FacebookIcon from '@mui/icons-material/Facebook';
import Input from './../components/Input/Input';
import { useMedium } from './MediumContext';

const AuthStateContext = createContext({
  isAuthenticated: false,
  isInitialised: false,
  user: null,
});

export function useAuth() {
  return useContext(AuthStateContext);
}

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const [loading, setLoading] = useState(false);
  const [openAuth, setOpenAuth] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const [isAuthenticated, setIsAuthenticated] = useState((user) ? user.access_token : false);
  const [auth_user_type, setAuthUserType] = useState((user) ? user.auth_type : false);
  const initialState = {
    first_name: '', last_name: '', mobile_no: '', email: '', medium_code: '', course: '', board_id: '', class_id: '', state_exam_id: '', exam_id: '', otp: '', password: '', confirmPassword: '', level: '',
  };
  const [formData, setFormData] = useState(initialState);
  const [mainAuth, setMainAuth] = useState(true);
  const [otpVerification, setOtpVerification] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userType, setUserType] = useState(3);
  const [forgotPasswordPopup, setForgotPasswordPopup] = useState(false);
  const dispatch = useDispatch();
  const [mediums, setMediums] = useState(false);
  const [classGroups, setClassGroups] = useState(false);
  const [boards, setBoards] = useState(false);
  const [classes, setClasses] = useState(false);
  const [states, setStates] = useState(false);
  const [exams, setExams] = useState(false);
  const { userMedium, setUserMedium } = useMedium();
  // Redirect to dashboard
  useEffect(() => {
    if (isAuthenticated && !currentPath.includes("dashboard")) {
      navigate('/dashboard');
    }
  }, [location]);

  useEffect(() => {
    fetchData()
  }, []);

  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post('/api/frontend/get-mediums')
        .then(response => {
          if (response.data.response_code === 200) {
            setMediums(response.data.data);
          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });

      // axios.post('/api/frontend/get-group-lists')
      //   .then(response => {
      //     if (response.data.response_code === 200) {
      //       setClassGroups(response.data.data);
      //     } else if (response.data.response_code === 401) {

      //     } else {

      //     }
      //     setLoading(false);
      //   })
      //   .catch(function (error) {
      //     setLoading(false);
      //     console.log(error);
      //   });

      // });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
    * This function is used to get Chapter list of selected Class and Subjects.
    */
  const _fn_get_boards = async () => {
    setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post('/api/frontend/get-boards')
        .then(response => {
          if (response.data.response_code === 200) {
            setBoards(response.data.data);
          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * This function is used to get Chapter list of selected Class and Subjects.
   * @param {*} class_exam_id 
   */
  const _fn_get_board_classes = async (board_id) => {
    setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post('/api/frontend/get-board-classes', { 'board_id': board_id })
        .then(response => {
          if (response.data.response_code === 200) {
            setClasses(response.data.data);
          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * This function is used to get Chapter list of selected Class and Subjects.
   */
  const _fn_get_centralExams = async () => {
    setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post('/api/frontend/get-central-exams')
        .then(response => {
          if (response.data.response_code === 200) {
            setExams(response.data.data);
          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * This function is used to get Chapter list of selected Class and Subjects.
   */
  const _fn_get_states = async () => {
    setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post('/api/frontend/get-states')
        .then(response => {
          if (response.data.response_code === 200) {
            setStates(response.data.data);
          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * This function is used to get Chapter list of selected Class and Subjects.
   * @param {*} state_id 
   */
  const _fn_get_state_exams = async (state_exam_id) => {
    setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post('/api/frontend/get-state-exams', { 'state_exam_id': state_exam_id })
        .then(response => {
          if (response.data.response_code === 200) {
            setExams(response.data.data);
          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
     * This function is used to get Chapter list of selected Class and Subjects.
     * @param {*} class_exam_id 
     * @param {*} selected_subject_id 
     */
  const _fn_get_group_classes = async (class_group_id) => {
    setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post('/api/frontend/get-group-classes', { 'class_group_id': class_group_id })
        .then(response => {
          if (response.data.response_code === 200) {
            setClasses(response.data.data);
          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  }

  useEffect(() => {

    /**
     * Check User logged IN.
     * @author Pavan Ji <dropmail2pavan@gmail.com>
     */
    checkAuth()

  }, [navigate, openAuth, location]);

  const handleClose = () => {
    setOpenAuth(false);
    setFormData(initialState);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  /**
   * This function is user to signout from login user.
   * @param {*} id  // Product ID
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const authSignOut = async () => {
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('api/frontend/user/logout')
          .then(response => {
            if (response.data.status_code === 200) {
              localStorage.removeItem('profile');
              localStorage.removeItem('invc-medium');
              localStorage.clear();
              setUser(false);
              setIsAuthenticated(false);
              setAuthUserType(false);
            } else {
              setUser(false);
              setIsAuthenticated(false);
              setAuthUserType(false);
              localStorage.removeItem('profile');
              localStorage.removeItem('invc-medium');
              localStorage.clear();
            }
            swal({
              title: "Sign Out",
              text: response.data.message,
              icon: "success",
              timer: 1000,
              button: false,
            }).then(() => {
              if (currentPath.includes("/dashboard")) {
                navigate('/');
              }
            });
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
            setUser(false);
            setIsAuthenticated(false);
            setAuthUserType(false);
            localStorage.removeItem('profile');
            localStorage.clear();
            swal({
              title: "Sign Out",
              text: response.data.message,
              icon: "success",
              timer: 1000,
              button: false,
            }).then(() => {
              if (currentPath.includes("/dashboard")) {
                navigate('/');
              }
            });
          });

      });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * This function is user to signout from login user.
   * @param {*} id  // Product ID
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const logout = async () => {
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('api/frontend/user/logout')
          .then(response => {
            if (response.data.status_code === 200) {
              localStorage.removeItem('profile');
              localStorage.clear();
              setUser(false);
              setIsAuthenticated(false);
              setAuthUserType(false);
            } else {
              setUser(false);
              setIsAuthenticated(false);
              setAuthUserType(false);
              localStorage.removeItem('profile');
              localStorage.clear();
            }
            swal({
              title: "Sign Out",
              text: response.data.message,
              icon: "success",
              timer: 1000,
              button: false,
            }).then(() => {
              if (currentPath.includes("/dashboard")) {
                navigate('/');
              }
            });
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
            setUser(false);
            setIsAuthenticated(false);
            setAuthUserType(false);
            localStorage.removeItem('profile');
            localStorage.clear();
            swal({
              title: "Sign Out",
              text: response.data.message,
              icon: "success",
              timer: 1000,
              button: false,
            }).then(() => {
              if (currentPath.includes("/dashboard")) {
                navigate('/');
              }
            });
          });

      });
    } catch (e) {
      setLoading(false);
    }
  }
  // log out function to log the user out of google and set the profile array to null
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      /**
       * Login In 
       */
      // axios.get('/sanctum/csrf-cookie').then(async response => {
      setLoading(true);
      axios.post('/api/frontend/user/social-login', codeResponse).then((response) => {
        var data = response.data;
        if (data !== undefined) {
          if (data.status_code === 200) {
            localStorage.setItem('profile', JSON.stringify({ ...data.data }));
            setUser(data.data);
            setIsAuthenticated(true);
            setAuthUserType(data.data.auth_type);
            swal({
              title: "Sign In",
              text: data.message,
              icon: "success",
              button: false,
              timer: 1000,
            }).then(() => {
              if (data.data.auth_type === '2' || data.data.auth_type === 2) {
                navigate('/dashboard');
              } else {
              }
              handleClose();
            });
            setLoading(false);
          } else if (data.status_code === '401' || data.status_code === 401) {
            /**
             * Credentials failed
             */
            var messageText = '';
            if (data.errors.email) {
              messageText = data.errors.email[0];
            }
            if (data.errors.password) {
              messageText = messageText + '\n' + data.errors.password[0];
            }
            swal({
              title: data.message,
              text: messageText,
              icon: "warning",
            });
            setLoading(false);
          }
        }
      });
      setLoading(false);
      // });

      // dispatch(gmailSigninSignup(codeResponse, navigate)).then((data) => {
      //   if (data !== undefined) {
      //     if (data.status_code === 200) {
      //       handleClose();
      //     } else if (data.status_code === '401') {
      //       /**
      //        * Login failed
      //        */
      //       var messageText = '';
      //       if (data.errors.email) {
      //         messageText = data.errors.email[0];
      //       }
      //       if (data.errors.password) {
      //         messageText = messageText + '\n' + data.errors.password[0];
      //       }
      //       swal({
      //         title: data.message,
      //         text: messageText,
      //         icon: "warning",
      //       });
      //     } else {
      //       swal({
      //         title: "Server not responding!",
      //         text: 'Please try again!',
      //         icon: "warning",
      //       });
      //     }
      //   }
      // });
    },
    onError: (error) => {
      console.log('Login Failed:', error)
    }
  });
  /**
   * This function is use for social login from facebook.
   * @param {*} response 
   */
  const responseFacebook = (response) => {
    console.log(response);
  }

  const handleShowOtp = () => setShowOtp((prevShowOtp) => !prevShowOtp);

  const handleShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);

  const handleShowConfirmPassword = () => setShowConfirmPassword((prevConfirmShowPassword) => !prevConfirmShowPassword);

  const goBackToMainAuth = () => {
    setOtpVerification(false);
    setMainAuth(true);
    setForgotPasswordPopup(false);
  };

  const mainAuthToOtpVerification = (e) => {
    e.preventDefault();
    if (isSignUp) {
      setOtpVerification(false);
      setLoading(true);
      // axios.get('/sanctum/csrf-cookie').then(async response => {
      axios.post('/api/frontend/user/signup', formData).then((res) => {
        var data = res.data;
        if (data !== undefined) {
          if (data.response_code === 200) {
            setOtpVerification(true);
            setMainAuth(false);
            swal({
              text: data.message,
              icon: "success",
              timer: 1500,
              button: false,
            })
            setLoading(false);

          } else if (data.response_code === '401' || data.response_code === 401) {

            var messageText = '';
            if (data.errors.email) {
              messageText = data.errors.email[0];
            }
            if (data.errors.password) {
              messageText = messageText + '\n' + data.errors.password[0];
            }
            swal({
              title: data.message,
              text: messageText,
              icon: "warning",
            });
          } else {
            swal({
              // title: "Server not responding!",
              text: 'Please try again!',
              icon: "warning",
            });
          }
        }
        setLoading(false);
      });
      // });
    } else {
      /**
       * Frontend user login with OTP
       */
      // axios.get('/sanctum/csrf-cookie').then(async response => {
      setLoading(true);
      axios.post('/api/frontend/user/login-with-otp', formData).then((response) => {
        var data = response.data;
        if (data !== undefined) {
          if (data.response_code === 200) {
            if (data.data.register === 1) {
              setIsSignUp(true);
            } else {
              setOtpVerification(true);
              setMainAuth(false);
              swal({
                text: data.message,
                icon: "success",
                timer: 1500,
                button: false,
              })
            }
            setLoading(false);
          } else if (data.status_code === '401' || data.status_code === 401) {
            /**
             * Credentials failed
             */
            var messageText = '';
            if (data.errors.mobile_no) {
              messageText = data.errors.mobile_no;
            }
            swal({
              title: data.message,
              text: messageText,
              icon: "warning",
              timer: 1500,
            });
            setLoading(false);
          }
        }
      });
      setLoading(false);
      // });

    }
  };

  /**
   * Check user/Student SignIn.
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const resendOTP = () => {
    console.log('formData', formData);
    axios.post('/api/frontend/user/login-with-otp', formData).then((response) => {
      var data = response.data;
      if (data !== undefined) {
        if (data.response_code === 200) {
          if (data.data.register === 1) {
            setIsSignUp(true);
          } else {
            setOtpVerification(true);
            setMainAuth(false);
            swal({
              text: data.message,
              icon: "success",
              timer: 1500,
              button: false,
            })
          }
          setLoading(false);
        } else if (data.status_code === '401' || data.status_code === 401) {
          /**
           * Credentials failed
           */
          var messageText = '';
          if (data.errors.mobile_no) {
            messageText = data.errors.mobile_no;
          }
          swal({
            title: data.message,
            text: messageText,
            icon: "warning",
            timer: 1500,
          });
          setLoading(false);
        }
      }
    });
    setLoading(false);
    return false;
  }

  // This function in usre to verify login or register mobile otp to login 
  const handleSubmit = (e) => {
    e.preventDefault();
    // axios.get('/sanctum/csrf-cookie').then(async response => {
    axios.post('/api/frontend/user/verify-mobile-otp', formData).then((res) => {
      var data = res.data;
      if (data !== undefined) {
        if (data.response_code === 200) {
          localStorage.setItem('profile', JSON.stringify({ ...data.data }));
          localStorage.setItem('invc-medium', data.data.user.medium_code);
          setUserMedium(data.data.user.medium_code);
          setUser(data.data);
          swal({
            text: data.message,
            icon: "success",
            timer: 1500,
          }).then(() => {
            setIsAuthenticated(true);
            dispatch({ type: 'AUTH', data });
            navigate('/dashboard');
          });
          handleClose();
        } else if (data.response_code === '401' || data.response_code === 401) {
          var messageText = '';
          if (data.errors.mobile_no) {
            messageText = data.errors.mobile_no;
          }
          if (data.errors.otp) {
            messageText = messageText + '\n' + data.errors.otp;
          }
          swal({
            title: data.message,
            text: messageText,
            icon: "warning",
            timer: 1500,
          });
        } else {
          swal({
            title: "Server not responding!",
            text: 'Please try again!',
            icon: "warning",
            timer: 1500,
          });
        }
      }
    });
    // });
  };

  // Submit after OTP confirm
  const handleUserType = (e) => {
    e.preventDefault();
    if (userType === 3) {
      setUserType(2);
      setFormData({
        ...formData, ['user_type']: 2
      });
    } else {
      setUserType(3);
      setFormData({
        ...formData, ['user_type']: 3
      });
    }
  };

  const handleForgotPassword = (e) => {
    e.preventDefault(e);
    setMainAuth(false);
    setForgotPasswordPopup(true);
  }

  /**
   * This function is user to forgot password.
   * @param {*} id  // Product ID
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const handleForgotPaswordSubmit = (e) => {
    e.preventDefault(e);
    setMainAuth(false);
    setForgotPasswordPopup(true);
    // axios.get('/sanctum/csrf-cookie').then(async response => {
    axios.post('/api/frontend/user/forgot-password', formData).then((res) => {
      var data = res.data;
      if (data !== undefined) {
        if (data.response_code === 200) {
          swal({
            // title: "Done!",
            text: data.message,
            icon: "success",
          });
          handleClose()
        } else if (data.response_code === '401') {
          swal({
            // title: 'Password assistance',
            text: data.message,
            icon: "warning",
          });
        } else {
          swal({
            // title: "Server not responding!",
            text: 'Please try again!',
            icon: "warning",
          });
        }
      }
    });
    // });

  }

  /**
   * Check user login and user type.
   * @param {*} id  // Product ID
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const checkAuth = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile !== undefined && profile !== null) {
      const token = profile.access_token;
      if (token) {
        setIsAuthenticated(profile.access_token);
        // setAuthUserType(((profile) ? profile.auth_type : false));
      } else {
        setIsAuthenticated(false);
        // setAuthUserType(false);
      }
      return token ? true : false;
    }
  }

  /**
   * Check user/Student SignIn.
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const checkUserAuth = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile !== undefined && profile !== null) {
      const token = profile.access_token;
      if (token) {
        setIsAuthenticated(profile.access_token);
        // if (profile.auth_type === '3' || profile.auth_type === 3) {
        //   setAuthUserType(profile.auth_type);
        //   return true;
        // }
      } else {
        setIsAuthenticated(false);
        setAuthUserType(false);
        return false;
      }
    }
    return false;
  }






  return (
    <>
      <AuthStateContext.Provider value={{
        user,
        setUser,
        // authSignIn,
        logout,
        authSignOut,
        isAuthenticated,
        auth_user_type,
        setOpenAuth,
        setIsSignUp,
        checkAuth,
        checkUserAuth,
        setUserType,
      }}>
        {children}
      </AuthStateContext.Provider>
      <Backdrop
        sx={{ color: '#015998', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={setLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        maxWidth="md"
        open={openAuth}
        onClose={handleClose}
        PaperProps={{ sx: { borderRadius: "20px", width: '450px' } }}
      >
        <div className="auth">
          <div className={`${((isSignUp) ? 'signup' : 'signin')} loginmodal`}>
            {mainAuth && (
              <form className="auth__main" onSubmit={mainAuthToOtpVerification}>
                <div className="loginmodalbox">
                  {/* <h4>100% OFF</h4>
                  <h5>You Are Our Lucky Member</h5> */}
                  <Grid container spacing={1}>
                    {isSignUp && (
                      <>
                        <Input
                          size={'small'}
                          label={'First Name'}
                          type="text"
                          name="first_name"
                          placeholder="First Name"
                          value={formData.first_name}
                          half={true}
                          handleChange={(e) => {
                            if (e.target.value.length > 50) {
                              return false;
                            } else {
                              handleChange(e)
                            }
                          }}
                        // sx={{ marginBottom: '10px' }}
                        />
                        <Input
                          size={'small'}
                          label={'Last Name'}
                          name="last_name"
                          placeholder="Last Name"
                          type="text"
                          value={formData.last_name}
                          half={true}
                          handleChange={(e) => {
                            if (e.target.value.length > 50) {
                              return false;
                            } else {
                              handleChange(e)
                            }
                          }}
                        // sx={{ marginBottom: '10px' }}
                        />
                      </>
                    )}
                    <Input
                      size={'small'}
                      name="mobile_no"
                      label={'Mobile Number'}
                      placeholder="Mobile Number"
                      value={formData.mobile_no}
                      half={(isSignUp) ? true : false}
                      handleChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          var fitstNumber = parseInt(String(e.target.value)[0]);
                          if (fitstNumber < 6) {
                            return false;
                          }
                          if (e.target.value.length > 10) {
                            return false;
                          } else {
                            handleChange(e);
                          }
                        }
                      }}
                    // sx={{ marginBottom: '10px' }}
                    />
                    {isSignUp && (
                      <Input
                        label={'Enter email'}
                        placeholder="Enter email"
                        size={'small'}
                        name={'email'}
                        type={'email'}
                        value={formData.email}
                        half={(isSignUp) ? true : false}
                        handleChange={(e) => {
                          if (e.target.value.length > 45) {
                            return false;
                          } else {
                            handleChange(e)
                          }
                        }}
                      // sx={{ marginBottom: '10px' }}
                      />
                    )}
                    {isSignUp && (
                      <>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <FormControl fullWidth sx={{ textAlign: 'left' }}>
                            <InputLabel id="medium-select-label">Select Medium *</InputLabel>
                            <Select
                              required
                              size={'small'}
                              labelId="medium-select-label"
                              id="medium-select"
                              value={formData.medium_code}
                              name="medium_code"
                              onChange={handleChange}
                              sx={{ background: '#FFF', height: '48px', borderRadius: '10px' }}
                            >
                              {/* <MenuItem value="" key="sg">Select Medium</MenuItem> */}
                              {mediums && mediums.map((row) =>
                                <MenuItem value={row.medium_code} key={row.medium_code}>{row.medium_name}</MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <FormControl fullWidth sx={{ textAlign: 'left' }}>
                            <InputLabel id="medium-select-label">Select Course *</InputLabel>
                            <Select
                              // displayEmpty
                              required
                              size={'small'}
                              labelId="course-select-label"
                              id="course-select"
                              value={formData.course}
                              name="course"
                              onChange={(e) => {
                                handleChange(e)
                                if (e.target.value === 'class') {
                                  _fn_get_boards();
                                }
                                if (e.target.value === 'central-exam') {
                                  _fn_get_centralExams();
                                }
                                if (e.target.value === 'state-exam') {
                                  _fn_get_states();
                                }
                              }}
                              sx={{ background: '#FFF', height: '48px', borderRadius: '10px' }}
                            >
                              <MenuItem value="">Select Course *</MenuItem>
                              <MenuItem value="class">Class</MenuItem>
                              <MenuItem value="central-exam">Central Exam</MenuItem>
                              <MenuItem value="state-exam">State Exam</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {(formData.course === 'class') ? (
                          <>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <FormControl fullWidth sx={{ textAlign: 'left' }}>
                                <InputLabel id="class-group-select-label">Select Board*</InputLabel>
                                <Select
                                  required
                                  size={'small'}
                                  labelId="class-board-select-label"
                                  id="class-board-select"
                                  label="Select Board*"
                                  value={formData.board_id}
                                  name="board_id"
                                  // onChange={handleChange}
                                  onChange={(e) => {
                                    if (e.target.value) {
                                      _fn_get_board_classes(e.target.value);
                                      handleChange(e)
                                    } else {
                                      setClasses(false);
                                    }
                                  }}
                                  sx={{ background: '#FFF', height: '48px', borderRadius: '10px' }}
                                >
                                  {boards && boards.map((row) =>
                                    <MenuItem value={row.id} key={row.id}>{row.name}</MenuItem>
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <FormControl fullWidth sx={{ textAlign: 'left' }}>
                                <InputLabel id="class-select-label">Select Class *</InputLabel>
                                <Select
                                  required
                                  size={'small'}
                                  labelId="class-select-label"
                                  id="class-select"
                                  label="Select Class"
                                  value={formData.class_id}
                                  name="class_id"
                                  onChange={handleChange}
                                  sx={{ background: '#FFF', height: '48px', borderRadius: '10px', marginBottom: '10px' }}
                                >
                                  {classes && classes.map((row) =>
                                    <MenuItem value={row.id} key={row.id}>{row.class_name}</MenuItem>
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        ) : ''}
                        {(formData.course === 'state-exam') ? (
                          <>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <FormControl fullWidth sx={{ textAlign: 'left' }}>
                                <InputLabel id="class-group-select-label">Select State*</InputLabel>
                                <Select
                                  required
                                  size={'small'}
                                  labelId="state-exam-select-label"
                                  id="state-exam-select"
                                  label="Select State*"
                                  value={formData.state_exam_id}
                                  name="state_exam_id"
                                  onChange={(e) => {
                                    if (e.target.value) {
                                      _fn_get_state_exams(e.target.value);
                                      handleChange(e)
                                    } else {
                                      setExams(false);
                                    }
                                  }}
                                  sx={{ background: '#FFF', height: '48px', borderRadius: '10px' }}
                                >
                                  {states && states.map((row) =>
                                    <MenuItem value={row.id} key={row.id}>{row.name}</MenuItem>
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        ) : ''}
                        {((formData.course === 'central-exam') || formData.course === 'state-exam') ? (
                          <>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <FormControl fullWidth sx={{ textAlign: 'left' }}>
                                <InputLabel id="class-select-label">Select Exam *</InputLabel>
                                <Select
                                  required
                                  size={'small'}
                                  labelId="exam-select-label"
                                  id="exam-select"
                                  label="Select Exam"
                                  value={formData.exam_id}
                                  name="exam_id"
                                  onChange={handleChange}
                                  sx={{ background: '#FFF', height: '48px', borderRadius: '10px', marginBottom: '10px' }}
                                >
                                  {exams && exams.map((row) =>
                                    <MenuItem value={row.id} key={row.id}>{row.name}</MenuItem>
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        ) : ''}

                      </>
                    )}
                  </Grid>
                  <LoadingButton
                    className="btnnxt"
                    type="submit"
                    size={'small'}
                    loading={loading}
                    variant="contained"
                  >
                    {((isSignUp) ? 'Sign Up' : 'Login')}
                  </LoadingButton>

                  <FacebookLogin
                    // appId={facebookAppId}
                    autoLoad={false}
                    appId=""
                    fields="name,email,picture"
                    callback={responseFacebook}
                    cssClass="whtebtn pull-left"
                    // icon={<FacebookIcon />}
                    textButton="&nbsp;&nbsp;Facebook"
                  />
                  <Button
                    type="button" className="whtebtn pull-right" onClick={() => login()}
                    sx={{ padding: '6px !important' }}
                  >
                    Google
                  </Button>
                </div>
                <p style={{ color: '#FFF' }}>
                  By signing up, you agree to our
                  {' '}
                  <Link to="/terms-of-service"><span>Terms</span></Link>
                  {' '}
                  and
                  {' '}
                  <Link to="/privacy-policy"><span>Privacy Policy.</span></Link>
                </p>
                <center className="auth__switchMode">
                  {isSignUp ? (
                    <p style={{ color: '#FFF' }}>
                      Already have an Account?
                      {' '}
                      <span onClick={() => setIsSignUp(false)} style={{ color: '#FFF' }}>Sign in here</span>
                    </p>
                  ) : (
                    <p style={{ color: '#FFF' }}>
                      Don&apos;t have an Account?
                      {' '}
                      <span onClick={() => setIsSignUp(true)} style={{ color: '#FFF' }}>Sign up here</span>
                    </p>
                  )}
                </center>

              </form>
            )}

            {otpVerification && (
              <form className="auth__otp" onSubmit={handleSubmit}>
                <div className="loginmodalbox">
                  <IconButton className="auth__otpGoBack" onClick={goBackToMainAuth} sx={{ background: '#FFF' }}>
                    <ArrowBackIcon sx={{ color: "#FFF" }} />
                  </IconButton>
                  {/* <h4>100% OFF</h4>
                  <h5>You Are Our Lucky Member</h5> */}
                  <p style={{ color: '#FFF', float: 'left' }}>Enter OTP Sent to your Mobile Number</p>
                  <Input
                    name="otp"
                    label="Enter OTP"
                    placeholder="Enter OTP"
                    handleChange={handleChange}
                    type={showOtp ? 'text' : 'password'}
                    handleShowPassword={handleShowOtp}
                    sx={{ marginBottom: '15px', background: '#FFFFFF', borderRadius: '10px' }}
                  />
                  <Button
                    type="button" className="whtebtn pull-right" onClick={() => resendOTP()}
                    sx={{ padding: '6px !important', width: 'unset !important', fontSize: '15px !important' }}
                  >
                    Resend OTP
                  </Button>
                  <br />
                  <br />
                  <br />
                  <Button size="medium" type="submit" disabled={formData.otp.length !== 5} className="btnnxt">VERIFY</Button>
                </div>
              </form>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AuthProvider;