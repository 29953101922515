import React, { useEffect, useState, SyntheticEvent, useRef } from 'react';
import { Link, useParams, NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from './../../../contexts/AuthContext';
import Header from './../Header/Header';
import Footer from './../Footer/Footer';
import { Carousel } from 'primereact/carousel';
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";

import * as Yup from 'yup';
import {
  Alert, Backdrop, CircularProgress,
} from '@mui/material';
import { useMedium } from 'app/contexts/MediumContext';
import axios from 'axios';
import MediumDialog from './MediumDialog';
import swal from 'sweetalert';

function ExamPreviousYearPaperDetails() {
  const scollToRef = useRef();
  const params = useParams();
  const navigate = useNavigate();
  const { user, authSignOut, isAuthenticated, setOpenAuth, setIsSignUp } = useAuth();
  const [loading, setLoading] = useState(false);
  const [exam_category_slug, setExamCategorySlug] = useState(params.exam_category_slug);
  const [exam_group_slug, setExamGroupSlug] = useState(params.exam_group_slug);
  const [examCategory, setExamCategory] = useState(false);
  const [examGroup, setExamGroup] = useState(false);
  const [slug, setSlug] = useState(params.slug);
  const [class_exam_id, setClassExamId] = useState(params.row_id);
  const [classDetail, setClassDetail] = useState(false);
  const [pyps, setPyps] = useState(false);
  const { userMedium } = useMedium();
  const [open, setOpen] = useState((!userMedium) ? false : true);

  /**
   * Effect Handler on slug and navigation change 
   */
  useEffect(() => {

    if (userMedium) {
      fetchData(slug);
    }
    fetchExamCategory();
  }, []);

  /**
   * Load data on medium change
   */
  useEffect(() => {
    if (userMedium) {
      fetchData(slug);
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [userMedium]);

  /**
     * Fetching data from server.
     */
  const fetchExamCategory = async () => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/frontend/get-exam-category', {
          'exam_category_slug': exam_category_slug,
        })
          .then(response => {
            if (response.data.response_code === 200) {
              setExamCategory(response.data.data)
            } else if (response.data.response_code === 401) {

            } else {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
        axios.post('/api/frontend/get-exam-group', {
          exam_category_slug: exam_category_slug,
          exam_group_slug: exam_group_slug
        })
          .then(response => {
            if (response.data.response_code === 200) {
              setExamGroup(response.data.data)
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/frontend/get-exam-pyps-details', {
          'slug': slug,
          'medium_code': userMedium,
        })
          .then(response => {
            if (response.data.response_code === 200) {
              var class_exam_id = response.data.data.classDetail.id;
              setClassExamId(class_exam_id);
              setClassDetail(response.data.data.classDetail)
              setPyps(response.data.data.pyps)
            } else if (response.data.response_code === 401) {

            } else {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * This function is used to Create or Start Chapter Quiz.
   * @param {*} chapter_id 
   */
  const _hendelPypQuizStart = async (previous_year_paper_id) => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        var api_url = '/api/frontend/create-exam-previous-year-paper-quiz';
        if (isAuthenticated) {
          api_url = '/api/frontend/auth/create-exam-previous-year-paper-quiz';
        }
        axios.post(api_url, {
          'previous_year_paper_id': previous_year_paper_id,
          'class_exam_id': class_exam_id,
          user_medium: userMedium
        })
          .then(response => {
            if (response.data.response_code === 200) {
              if (response.data.data.quiz_key) {
                navigate(`/exam/previous-year-paper-quiz/${response.data.data.quiz_key}`);
              }
            } else if (response.data.response_code === 403) {
              // Open Login popup with message
              swal({
                text: response.data.message,
                icon: "info",
                buttons: [
                  'Cancel',
                  'Login'
                ],
                // timer: 2500
              }).then(function (isConfirm) {
                handleOpen(false);
              });
            } else {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
    * Handle login auth
    * @param {*} authState 
    */
  const handleOpen = (authState) => {
    if (authState) {
      setIsSignUp(true);
    } else {
      setIsSignUp(false);
    }
    setOpenAuth(true);
  }

  return (
    <>
      <Header slug={slug} setSlug={setSlug} />
      <Backdrop
        sx={{ color: '#015998', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={setLoading}
      >
        <CircularProgress color="secondary" />
      </Backdrop>

      <MediumDialog open={open} />


      <section id="hero" className="d-flex align-items-center inhead">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center" data-aos="fade-up" data-aos-delay="200">
              <h1>Previous Year Paper</h1>
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <ol>
              <li><Link to="/" title='Home'>Home</Link></li>
              {examCategory && (
                <li><Link to={`/${examCategory.slug}`} title={examCategory.name}>{examCategory.name}</Link></li>
              )}
              {(examGroup) ? (
                <>
                  <li><Link to={`/${examCategory.slug}/${examGroup.slug}`} title={examGroup.name}>{examGroup.name}</Link></li>
                  <li><Link to={`/${classDetail.exam_category_slug}/${examGroup.slug}/exam/includes/${classDetail.slug}`} title={classDetail.name}>{classDetail.name}</Link></li>
                </>
              ) : (
                <li><Link to={`/${classDetail.exam_category_slug}/exam/includes/${classDetail.slug}`} title={classDetail.name}>{classDetail.name}</Link></li>
              )
              }
              < li > Previous Year Paper</li>
            </ol>
          </div>
        </section>

        <section className="inner-page listing-page">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="tabdesign" ref={scollToRef}>
                  <nav>
                    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                      <div className={'nav-link active'}
                        id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home"
                        aria-selected="true"
                      >
                        {classDetail.class_name} : Previous Year Paper

                      </div>
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                      {pyps && (pyps.length) ? (
                        <ul>
                          {
                            pyps.map((row, index) => (
                              <li key={index}>
                                <Link onClick={() => _hendelPypQuizStart(row.id)} to={'#'} title={row.name}>
                                  <span>{row.name}</span>
                                  {row.description}
                                  <i className="bi bi-arrow-right"></i>
                                </Link>
                              </li>
                            )
                            )
                          }
                        </ul>
                      ) : (
                        <>
                          {pyps && (
                            <Alert severity="error" style={{ textAlign: 'center' }}>No Previous Year Paper found in this Class — check it out!</Alert>
                          )}
                        </>
                      )}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </main >


      <Footer />
    </>
  );
}
export default ExamPreviousYearPaperDetails;
