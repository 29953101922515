import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../../auth/authRoles';

const MyCourses = Loadable(lazy(() => import('./MyCourses')));
const AllClassCourses = Loadable(lazy(() => import('./AllClassCourses')));
const AllExamCourses = Loadable(lazy(() => import('./AllExamCourses')));
const coursesRoutes = [
  { path: '/dashboard/courses/my-courses', element: <MyCourses />, auth: authRoles.admin },
  { path: '/dashboard/courses/all-class-courses', element: <AllClassCourses />, auth: authRoles.admin },
  { path: '/dashboard/courses/all-exam-courses', element: <AllExamCourses />, auth: authRoles.admin },
];

export default coursesRoutes;
