import React from 'react';
import { Link } from 'react-router-dom';
import Header from './../Header/Header';
import Footer from './../Footer/Footer';
import OurTeams from './../HomePage/OurTeams';
import Testimonials from './../HomePage/Testimonials';




function AboutUs() {
  return (
    <>
      <Header />
      <section id="hero" className="d-flex align-items-center inhead">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center" data-aos="fade-up" data-aos-delay="200">
              <h1>About Us</h1>
            </div>
          </div>
        </div>
      </section>
      <main id="main">
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <ol>
              <li><Link to="/" title='Home'>Home</Link></li>
              <li>About Us</li>
            </ol>
          </div>
        </section>
        <br />
        <div className="main_content info-page">
          <div className="section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row align-items-center prod-detail">
                    <div className="col-12">

                      <h1 className="btn-shine mt-30">Welcome to Invictaa</h1>
                      <p>At Invictaa, our primary focus is to provide high-quality education and comprehensive guidance to
                        students from nursery to grade 12, as well as aspiring candidates preparing for government jobs and
                        competitive exams like JEE (Joint Entrance Examination) and NEET (National Eligibility cum Entrance Test).
                        We believe that education is the key to unlocking the full potential of every individual.
                        Our mission is to empower learners of all ages and backgrounds with knowledge and skills that
                        will enable them to succeed in their personal and professional lives. We are committed to providing a
                        dynamic and innovative learning environment that fosters intellectual curiosity, critical thinking, and
                        creativity.</p>
                      <h2>Why Choose Invictaa:</h2>
                      <p>
                        <strong>Experienced Faculty: </strong>
                        Our faculty members are highly qualified and experienced in their respective fields. They possess a deep
                        understanding of the education landscape and are dedicated to delivering effective teaching methodologies.
                      </p>

                      <p>
                        <strong>Personalized Approach: </strong>
                        We believe in recognizing the unique strengths and weaknesses of each student. Our personalized approach ensures that
                        individual learning needs are met, enabling students to reach their full potential.
                      </p>

                      <p>
                        <strong>Comprehensive Study Material: </strong>
                        We provide well-researched and up-to-date study materials designed to cover the entire syllabus effectively.
                        Our materials are structured to foster in-depth understanding and conceptual clarity.
                      </p>

                      <p>
                        <strong>Supportive Learning Environment: </strong>
                        We strive to create a supportive and encouraging learning environment where students feel motivated
                        to excel academically and personally.
                      </p>
                      <p>
                        Invictaa is more than just a learning platform; it's a thriving community of learners.
                        We encourage collaboration and networking among our learners, providing opportunities to connect, share ideas, and learn from one another.
                        Our community support ensures that you never feel alone on your learning journey.
                      </p>
                      <p>
                        Join Invictaa today and embark on an enriching educational experience that will empower you to achieve your goals and reach
                        new heights of success.
                        Together, we can unlock the power of education and shape a brighter future for everyone.
                      </p>

                      <h3>Remember, "Invictaa" stands for invincible education - where learning knows no bounds!</h3>
                      <h3>("इन्विक्टा" का अर्थ अजेय शिक्षा है - जहाँ सीखने की कोई सीमा नहीं है!)</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <OurTeams />

        <Testimonials />
        <Footer />
      </main >
    </>
  );
}
export default AboutUs;
