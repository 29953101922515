import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  TextField, Button, Grid, Divider,
} from '@mui/material';

import * as Yup from 'yup';
import { Formik } from 'formik';
import swal from 'sweetalert';
import axios from 'axios';

import Header from './../Header/Header';
import Footer from './../Footer/Footer';
import OurTeams from './../HomePage/OurTeams';
import Testimonials from './../HomePage/Testimonials';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ButtonMailto from './ButtonMailto';
const validationSchema = Yup.object().shape({
  full_name: Yup.string()
    .required('Name field is required!'),
  email: Yup.string()
    .required('Email field is required!')
    .email('Enter valid email address!'),
  subject: Yup.string()
    .required('Subject field is required!'),
  message: Yup.string()
    .required("Message field is required!")
});

function ContactUs() {
  const [loading, setLoading] = useState(false);
  const initialValues = {
    full_name: '',
    email: '',
    subject: '',
    message: '',
  }

  const handleFormSubmit = (values, errors) => {
    try {
      axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/frontend/save-contact-us-data', values)
          .then(response => {
            if (response.data.response_code === 200) {
              swal({
                text: response.data.message,
                icon: "success",
                timer: 1500,
              }).then(() => {
                window.location.reload(false);
                // values.full_name = '';
                // values.email = '';

              });
            } else {
              swal({
                text: response.data.message,
                icon: "warning",
                timer: 1500,
              });
              errors.setErrors(response.data.errors);
            }
            setLoading(false);
          })
          .catch(function (error) {
            console.error(error);
          });
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  // const ButtonMailto = ({ mailto, label }) => {
  //   return (
  //     <Link
  //       to='#'
  //       onClick={(e) => {
  //         window.location.href = mailto;
  //         e.preventDefault();
  //       }}
  //     >
  //       {label}
  //     </Link>
  //   );
  // };

  return (
    <>
      <Header />
      <section id="hero" className="d-flex align-items-center inhead">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center" data-aos="fade-up" data-aos-delay="200">
              <h1>Contact Us</h1>
            </div>
          </div>
        </div>
      </section>
      <main id="main">
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <ol>
              <li><Link to="/" title='Home'>Home</Link></li>
              <li>Contact Us</li>
            </ol>
          </div>
        </section>
        <div className="main_content info-page contact-us">
          <div className="section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row align-items-center prod-detail">
                    <div className="col-12">
                      {/* <Divider /> */}
                      <br />
                      <br />
                      <p>
                        If you have any questions about this Privacy Policy, You can contact us:
                        <ul>
                          <li>•	By visiting this page on our website/Application: <Link to={'https://www.invictaa.com/'}> https://www.invictaa.com/</Link></li>
                          <li>•	By emailing us at: <ButtonMailto label="support@invictaa.com" mailto="mailto:support@invictaa.com" /></li>
                        </ul>
                      </p>
                      <br />
                      <br />
                      <Formik
                        onSubmit={(values, errors) => {
                          handleFormSubmit(values, errors);
                        }
                        }
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                      >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                          <form
                            onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                              <Grid item sm={6} xs={12}>
                                <div className='contact-page-img'>
                                  <img title="Contact Support" alt="Contact Support"
                                    src={`${process.env.PUBLIC_URL}/assets/frontend/img/skills.png`}
                                    style={{ maxWidth: '530px', width: '100%' }}
                                  />
                                </div>
                                <Divider />
                                <Grid container style={{ padding: '30px 20px 0px' }} spacing={4}>
                                  <Grid item sm={6} xs={12} lg={6}>
                                    <label>Contact Number:</label>
                                    <br />
                                    <PhoneIphoneIcon />
                                    {/* <Link to={'tel:+91 9540652679'}> +91 9540652679</Link> */}
                                    <span
                                      onClick={() => window.location = 'tel:911204271372'} title="+911204271372">
                                      +911204271372
                                    </span>
                                  </Grid>
                                  <Grid item sm={6} xs={12} lg={6}>
                                    <label>Contact Email:</label>
                                    <br />
                                    <MarkEmailReadIcon />
                                    <ButtonMailto label="support@invictaa.com" mailto="mailto:support@invictaa.com" />
                                  </Grid>
                                  <Grid item sm={6} xs={12} lg={12}>
                                    <Divider />
                                    <br />
                                    <label>Address:</label>
                                    <br />
                                    <LocationOnIcon /> A-403, Ithum Tower Sector-62 , Noida Uttar Pradesh–201309, India
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item sm={6} xs={12}>
                                <div className='contact-form'>
                                  <label htmlFor="full_name" className='form-label'>
                                    Full Name: <span className='req-star'>*</span>
                                  </label>
                                  <TextField

                                    fullWidth
                                    label={false}
                                    // size="small"
                                    type="text"
                                    name="full_name"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={values.full_name}
                                    onChange={handleChange}
                                    helperText={touched.full_name && errors.full_name}
                                    error={Boolean(errors.full_name && touched.full_name)}
                                    sx={{ mb: 2 }}
                                  />

                                  <label htmlFor="discount_percentage" className='form-label'>
                                    Email Address: <span className='req-star'>*</span>
                                  </label>
                                  <TextField
                                    fullWidth
                                    label={false}
                                    // size="small"
                                    type="email"
                                    name="email"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={values.email}
                                    onChange={handleChange}
                                    helperText={touched.email && errors.email}
                                    error={Boolean(errors.email && touched.email)}
                                    sx={{ mb: 2 }}
                                  />
                                  <label htmlFor="subject" className='form-label'>
                                    Subject: <span className='req-star'>*</span>
                                  </label>
                                  <TextField
                                    fullWidth
                                    label={false}
                                    // size="small"
                                    type="text"
                                    name="subject"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={values.subject}
                                    onChange={handleChange}
                                    helperText={touched.subject && errors.subject}
                                    error={Boolean(errors.subject && touched.subject)}
                                    sx={{ mb: 2 }}
                                  />
                                  <label htmlFor="description" className='form-label'>
                                    Message: <span className='req-star'>*</span>
                                  </label>
                                  <TextField
                                    fullWidth
                                    label={false}
                                    multiline={true}
                                    minRows={2}
                                    // size="small"
                                    type="text"
                                    name="message"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={values.message}
                                    onChange={handleChange}
                                    helperText={touched.message && errors.message}
                                    error={Boolean(errors.message && touched.message)}
                                    sx={{ mb: 2 }}
                                  />
                                  <Grid container spacing={4}>
                                    <Grid item sm={4} xs={3} lg={4}>
                                    </Grid>
                                    <Grid item sm={4} xs={6} lg={4}>
                                      <Button
                                        fullWidth
                                        disabled={loading}
                                        variant="contained"
                                        type='submit'
                                        sx={{ mb: 2, mt: 4 }}
                                      >
                                        Submit
                                      </Button>
                                    </Grid>
                                    <Grid item sm={4} xs={3} lg={4}>
                                    </Grid>
                                  </Grid>
                                </div>
                              </Grid>
                            </Grid>

                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />

          {/* <DownloadApp /> */}
        </div>

        <OurTeams />

        <Testimonials />
      </main >
      <Footer />
    </>
  );
}
export default ContactUs;
