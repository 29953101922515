export const navigations = [
  { name: 'Dashboard', path: '/dashboard', icon: 'dashboard' },
  {
    name: 'Courses',
    icon: 'class',
    children: [
      { name: 'My Courses', iconText: 'SI', path: '/dashboard/courses/my-courses' },
      { name: 'All Class Courses', iconText: 'SU', path: '/dashboard/courses/all-class-courses' },
      { name: 'All Exam Courses', iconText: 'SU', path: '/dashboard/courses/all-exam-courses' },
    ],
  },
  {
    name: 'Quiz History',
    icon: 'task',
    children: [
      { name: 'Today Quiz', iconText: 'SI', path: '/dashboard/quiz-history/today-quiz' },
      { name: 'All Quiz', iconText: 'SU', path: '/dashboard/quiz-history/all-quiz' },
    ],
  },
  // {
  //   name: 'Stock',
  //   icon: 'shoppingCart',
  //   children: [
  //     { name: 'Stock Available', iconText: 'SI', path: '/dashboard' },
  //   ],
  // },
  // {
  //   name: 'Accounts',
  //   icon: 'class',
  //   children: [
  //     { name: 'Ledger Entry', iconText: 'SI', path: '/dashboard' },
  //     { name: 'Ledger List', iconText: 'SU', path: '/dashboard' },
  //     { name: 'Hegan Settlement', iconText: 'SI', path: '/dashboard' },
  //   ],
  // },
  // {
  //   name: 'Reports',
  //   icon: 'task',
  //   children: [
  //     { name: 'Sales Reports', iconText: 'SI', path: '/dashboard' },
  //     { name: 'Order Cancelled', iconText: 'SU', path: '/dashboard' },
  //     { name: 'Order Refund', iconText: 'SU', path: '/dashboard' },
  //   ],
  // },
  // { name: 'Auto Update', path: '/dashboard', icon: 'autorenew' },
];
