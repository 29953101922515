import React, { useEffect, useState } from 'react';

import axios from 'axios';
import OwlCarousel from 'react-owl-carousel';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


function Testimonials() {

  const [professionalSkills, setProfessionalSkills] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // getProfessionalSkills()
    fetchData();
  }, []);

  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      //   axios.post('/api/frontend/get-professional-skills')
      //     .then(response => {
      //       // console.log(response.data);
      //       if (response.data.status_code === 200) {
      //         setProfessionalSkills(response.data.data);
      //       } else if (response.data.status === 401) {

      //       } else {

      //       }
      //       setLoading(false);
      //     })
      //     .catch(function (error) {
      //       setLoading(false);
      //       console.log(error);
      //     });
      // });
    } catch (e) {
      setLoading(false);
    }
  }

  const options = {
    nav: false,
    dots: true,
    // autoplay: true,
    loop: true,
    // padding: 20,
    margin: 20,
    responsiveClass: true,
    navText: ['<i class="ion-ios-arrow-left"></i>', '<i class="ion-ios-arrow-right"></i>'],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
        dots: true,
      },
      1000: {
        items: 2,
      }
    },
  };
  return (
    <>
      <section className="" style={{ paddingTop: '20px!important', paddingBottom: '20px!important' }}>
        <div className="container aos-init aos-animate" data-aos="fade-up">

          <div className="section-title">
            <h2>Our Students Words</h2>
          </div>

          <div className="row">
            <div className="col-md-12">
              <OwlCarousel className='carousel_slider owl-carousel owl-theme'
                {...options}>
                <div className="boxtestimonial">
                  <div className="topbox">
                    <img src={`${process.env.PUBLIC_URL}/assets/frontend/img/watermark.png`} />
                    <h4>Nandini Kumari</h4>
                    <h5>Delhi NCR</h5>
                  </div>
                  <div className="testimonial-content">
                    {/* <img  src={`${process.env.PUBLIC_URL}/assets/frontend/img/star.jpg`} sty /> */}
                    <p>This is a very helpful for me during exam preparation...... thankyou so much Invicta</p>
                  </div>
                </div>
                <div className="boxtestimonial">
                  <div className="topbox">
                    <img src={`${process.env.PUBLIC_URL}/assets/frontend/img/watermark.png`} />
                    <h4>Parvej Ali</h4>
                    <h5>Up Lucknow</h5>
                  </div>
                  <div className="testimonial-content">
                    {/* <img src={`${process.env.PUBLIC_URL}/assets/frontend/img/star.jpg`} /> */}
                    <p>Invicta app is very good for study, after completing the syllabus there will be no problem in getting selected for the test. Thanks</p>
                  </div>
                </div>
                {/* <div className="boxtestimonial">
                  <div className="topbox">
                    <img src={`${process.env.PUBLIC_URL}/assets/frontend/img/testimonial.jpg`} />
                    <h4>Ellie Goebel</h4>
                    <h5>Kong Yijun</h5>
                  </div>
                  <div className="testimonial-content">
                    <p>Mattis molestie a iaculis at. Pulvinar neque laoreet suspendisse interdum consectetur libero eget
                      Mattis molestie a iaculis at. Pulvinar neque laoreet suspendisse interdum consectetur </p>
                  </div>
                </div> */}

              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Testimonials;
