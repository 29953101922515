import { useNavigate, Link } from 'react-router-dom';

const Footer = () => {

  return (
    <>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">

              <div className="col-lg-3 col-md-6 footer-contact">
                <h4>About Invictaa</h4>
                <p>
                  At Invictaa, our primary focus is to provide high-quality education and comprehensive guidance to students from nursery to grade 12, as well as aspiring candidates preparing for government jobs and competitive exams like JEE (Joint Entrance Examination) and NEET (National Eligibility cum Entrance Test).
                  {/* We believe that education is the key to unlocking the full potential of every individual. Our mission is to empower learners of all ages and backgrounds with knowledge and skills that will enable them to succeed in their personal and professional lives. We are committed to providing a dynamic and innovative learning environment that fosters intellectual curiosity, critical thinking, and creativity. */}
                  {/* The Invictaa Learning Programs provide students a holistic learning experience. Students across regions can access the best teachers and see concepts come to life. Every detail of a student's journey is planned and executed at the deepest level with subject matter experts, */}
                </p>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li><i className="bx bx-chevron-right"></i> <Link to={'/'}>Home</Link></li>
                  <li><i className="bx bx-chevron-right"></i> <Link to={'/about-us'}>About us</Link></li>
                  {/* <li><i className="bx bx-chevron-right"></i> <Link to={'#'}>Services</Link></li> */}
                  <li><i className="bx bx-chevron-right"></i> <Link to={'/terms-of-service'}>Terms of service</Link></li>
                  <li><i className="bx bx-chevron-right"></i> <Link to={'/privacy-policy'}>Privacy policy</Link></li>
                  <li><i className="bx bx-chevron-right"></i> <Link to={'/refund-cancellation'}>Refund & Cancellation</Link></li>
                  <li><i className="bx bx-chevron-right"></i> <Link to={'/contact-us'}>Grievance Redressal</Link></li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li><i className="bx bx-chevron-right"></i> <Link to={'#'}>Services</Link></li>
                  <li><i className="bx bx-chevron-right"></i> <Link to={'/contact-us'}>Contact Us</Link></li>
                  {/* <li><i className="bx bx-chevron-right"></i> <Link to={'#'}>Web Development</Link></li>
                  <li><i className="bx bx-chevron-right"></i> <Link to={'#'}>Product Management</Link></li>
                  <li><i className="bx bx-chevron-right"></i> <Link to={'#'}>Marketing</Link></li>
                  <li><i className="bx bx-chevron-right"></i> <Link to={'#'}>Graphic Design</Link></li> */}
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Social Networks</h4>
                <p>
                  A-403, <br />
                  Ithum Tower Sector-62, <br />
                  Noida Uttar Pradesh–201309, India <br /><br />
                  <strong>Phone:</strong> +911204271372<br />
                  <strong>Email:</strong> support@invictaa.com<br />
                </p>
                <div className="social-links mt-3">
                  <a href={'https://www.facebook.com/invictaa1'} target='_blank' className="facebook" title='Facebook Logo'>
                    <i className="bx bxl-facebook"></i>
                  </a>
                  <a href={'https://instagram.com/invictaa1?igshid=OGQ5ZDc2ODk2ZA=='} target='_blank' className="instagram" title='Facebook Logo'><i className="bx bxl-instagram"></i></a>
                  <a href={'https://www.linkedin.com/company/invictaa/'} target='_blank' className="linkedin" title='Linkedin Logo'><i className="bx bxl-linkedin"></i></a>
                  <a href={'https://www.youtube.com/channel/UCkpLlu03SQCvK-wNW9YLOqg'} target='_blank' className="youtube" title='Youtube Logo'><i className="bx bxl-youtube"></i></a>
                  <a href={'https://play.google.com/store/apps/details?id=com.bhartieducation'} target='_blank' className="playstore" title='Play Store Logo'><i className="bx bxl-play-store"></i></a>

                </div>
              </div>

            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="copyright">
                  &copy; Copyright <strong><span>Bharti Education</span></strong>. All Rights Reserved.
                </div>
              </div>
            </div>

          </div>
        </div>
      </footer>
      {/* <div id="preloader"></div> */}
      <Link to={'#'} className="back-to-top d-flex align-items-center justify-content-center">
        <i className="bi bi-arrow-up-short"></i>
      </Link>
    </>
  );
};

export default Footer;
