import React, { useEffect, useState, useRef } from 'react';
// import useAuth from 'app/hooks/useAuth';
import { useAuth } from './../../../contexts/AuthContext';
import { useDispatch } from 'react-redux';
import { useNavigate, Link, useLocation, useSearchParams, useParams } from 'react-router-dom';
import {
  Avatar, IconButton, ListItemIcon, Menu, MenuItem, Select
} from '@mui/material';
// import Medium from './Medium';
import { useMedium } from 'app/contexts/MediumContext';
import axios from 'axios';
import { Formik } from 'formik';
import swal from 'sweetalert';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewListIcon from '@mui/icons-material/ViewList';
import LogoutIcon from '@mui/icons-material/Logout';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ShopTwoIcon from '@mui/icons-material/ShopTwo';
import AOS from 'aos';
import 'aos/dist/aos.css';

import "./../assets/vendor/bootstrap/css/bootstrap.min.css";
import "./../assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "./../assets/vendor/boxicons/css/boxicons.min.css";
import "./../assets/vendor/glightbox/css/glightbox.min.css";
import "./../assets/vendor/remixicon/remixicon.css";
import "./../assets/vendor/swiper/swiper-bundle.min.css";

import "./../assets/css/style.css";
import "./../assets/js/slick.css";
import "./../assets/js/slick-theme.css";
import "./../assets/css/custom.css";
import "./../assets/css/responsive.css";
import './Header.scss';

AOS.init();

const Header = ({ slug = '', setSlug }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const { user, authSignOut, isAuthenticated, setOpenAuth, setIsSignUp } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { activeMediums, userMedium, setUserMedium, handleUserMediumChange } = useMedium();

  const [openMainNav, setOpenMainNav] = useState(false);
  const [loading, setLoading] = useState(false);
  const [classMenues, setClassMenues] = useState(null);
  const [examMenues, setExamMenues] = useState(null);
  const ref = useRef()
  /**
   * Hendle Effect for Medium dis select on home page
   */
  useEffect(() => {
    loadClassesExamsSubMenus();
    // Selected Medium Remove on home page
    if ((currentPath === "/")) {
      window.localStorage.setItem("invc-medium", '')
      handleUserMediumChange('');
    }

    // console.log('slug', slug);
    // console.log('currentPath', currentPath);
    // console.log('currentPath', currentPath.includes("bihar-exams"));
    /**
     * Set default medium for selected Class
     */
    if (currentPath.includes("class")) {
      switch (slug) {
        case 'jee':
          setUserMedium('en');
          return slug;
        case 'neet':
          setUserMedium('en');
          return slug;
        case 'ssc-banking-railway':
          setUserMedium('hi');
          return slug;
        case 'upsc':
          setUserMedium('hi');
          return slug;
        // case 'samanaya-athhayayana-papara-1':
        //   setUserMedium('hi');
        //   return slug;
        case 'ncert':
          setUserMedium('hi');
          return slug;
        default:
          return slug;
      }
    }

    if (currentPath.includes("bihar-exams")) {
      setUserMedium('hi');
    }
    if (currentPath.includes("state-exams")) {
      setUserMedium('hi');
    }

  }, []);

  /**
   * Fetching data from server.
   */
  const loadClassesExamsSubMenus = async () => {
    // setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/frontend/load-classes-exams-sub-menues')
          .then(response => {
            if (response.data.response_code === 200) {
              setClassMenues(response.data.data.classMenues);
              setExamMenues(response.data.data.examMenues);
            } else if (response.data.response_code === 401) {

            } else {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * Hendle Effect on isAuthenticated changes
   */
  useEffect(() => {
  }, [isAuthenticated]);

  const handleOpen = (authState) => {
    if (authState) {
      setIsSignUp(true);
    } else {
      setIsSignUp(false);
    }
    setOpenAuth(true);

  }

  /**
   * Main Menu for mobile
   */

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setOpenMainNav(false);
    } else {
      setOpenMainNav(true);
    }
    const handleResize = e => {
      if (window.innerWidth <= 768) {
        setOpenMainNav(false);
      } else {
        setOpenMainNav(true);
      }
    }
    window.addEventListener('resize', handleResize)
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (e.target.className === 'bi mobile-nav-toggle bi-list' || e.target.className === 'bi mobile-nav-toggle bi-x') {
      } else {
        if (openMainNav && ref.current && !ref.current.contains(e.target) && window.innerWidth <= 768) {
          setOpenMainNav(false)
        }
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
  }, [openMainNav])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    swal({
      title: "Are you sure?",
      text: "You want to sign out.",
      icon: "info",
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
    }).then(function (isConfirm) {
      if (isConfirm) {
        setOpenAuth(false);
        /**
         * User logout
         */
        authSignOut()
      }
    });
  };

  return (
    <>
      {/*   ======= Header =======   */}
      <header id="header" className={(currentPath.includes("/chapter-quiz") ||
        currentPath.includes("/practice-test-quiz") ||
        currentPath.includes("/previous-year-paper-quiz") ||
        currentPath.includes("/test-series-quiz") ||
        currentPath.includes("/pyp-quiz") ||
        currentPath.includes("/quiz") ||
        currentPath.includes("/previous-year-question-quiz")
      ) ? "fixed-top header-inner-pages" : "fixed-top"}>
        <div className="container d-flex align-items-center">

          <h1 className="logo me-auto">
            <Link to="/">
              <img src={`${process.env.PUBLIC_URL}/assets/frontend/img/logo.png`} />
            </Link>
          </h1>
          {/* Uncomment below if you prefer to use an image logo  */}
          {/* <a href="index.html" className="logo me-auto"><img src="assets/img/logo.png" alt="" className="img-fluid"></a>  */}

          <nav id="navbar" className="navbar navbar-expand-md">
            <i className={`${((openMainNav) ? 'bi mobile-nav-toggle bi-x' : 'bi mobile-nav-toggle bi-list')}`} onClick={(e) => {
              if (openMainNav) {
                setOpenMainNav(false);
              } else {
                setOpenMainNav(true);
              }
            }}></i>
            <ul ref={ref} style={{ display: `${(openMainNav) ? ((window.innerWidth <= 768) ? 'block' : 'inline-flex') : 'none'}` }}>
              <li><Link className={(currentPath === "/") ? "nav-link scrollto active" : "nav-link scrollto"} to={"/"}>Home</Link></li>
              <li><Link className={(currentPath === "/about-us") ? "nav-link scrollto active" : "nav-link scrollto"} to={"/about-us"}>About Us</Link></li>
              <li className="dropdown">
                <Link className={(currentPath.includes("/classes") || currentPath.includes("/class")) ? "nav-link scrollto active" : "nav-link scrollto"} to={"#"}>
                  <span>Classes</span>  <i className="bi bi-chevron-down"></i>
                </Link>
                {classMenues && (classMenues.length) ? (
                  <ul>
                    {
                      classMenues.map((row, index) => (
                        (row.classes.length) ? (
                          <li className="dropdown" key={`sec-${index}`}>
                            <Link to={'#'} title={row.name}>
                              <span>{row.name}</span> <i className="bi bi-chevron-right"></i>
                            </Link>
                            {(row.classes) && (row.classes.length) ? (
                              <ul>
                                {(row.classes) && row.classes.map((row1, index1) => (
                                  <li key={`third-${index1}`}>
                                    <Link to={row1.url} title={row1.name}>
                                      <span>{row1.name}</span>
                                    </Link>
                                  </li>
                                )
                                )}
                              </ul>
                            ) : ''}
                          </li>
                        ) : (
                          <li key={`first-${index}`}>
                            <Link to={'#'} title={row.name}>
                              <span>{row.name}</span>
                            </Link>
                          </li>
                        )
                      )
                      )
                    }
                  </ul>
                ) : ''}

              </li>
              <li className="dropdown">
                <Link className={(currentPath.includes("/central-exams") || currentPath.includes("/state-exams")) ? "nav-link scrollto active" : "nav-link scrollto"} to={"#"}>
                  <span>Exams</span>  <i className="bi bi-chevron-down"></i>
                </Link>
                {examMenues && (examMenues.length) ? (
                  <ul>
                    {
                      examMenues.map((row, index) => (
                        (row.state_exam && row.state_exam.length) ? (
                          <li className="dropdown" key={`st-sec-${index}`}>
                            <Link to={'#'} title={row.name}>
                              <span>{row.name}</span> <i className="bi bi-chevron-right"></i>
                            </Link>
                            {(row.state_exam) && (row.state_exam.length) ? (
                              <ul>
                                {(row.state_exam) && row.state_exam.map((row1, index1) => (
                                  (row1.classes && row1.classes.length) ? (
                                    <li className="dropdown" key={`st-third-${index1}`}>
                                      <Link to={'#'} title={row1.name}>
                                        <span>{row1.name}</span> <i className="bi bi-chevron-right"></i>
                                      </Link>
                                      {(row1.classes) && (row1.classes.length) ? (
                                        <ul>
                                          {(row1.classes) && row1.classes.map((row2, index2) => (
                                            <li key={`st-fourth-${index2}`}>
                                              <Link to={row2.url} title={row2.name}>
                                                <span>{row2.name}</span>
                                              </Link>
                                            </li>
                                          )
                                          )}
                                        </ul>
                                      ) : ''}
                                    </li>
                                  ) : (
                                    <li key={`st-first-${index}`}>
                                      <Link to={'#'} title={row1.name}>
                                        <span>{row1.name}</span>
                                      </Link>
                                    </li>
                                  )
                                )
                                )}
                              </ul>
                            ) : ''}
                          </li>
                        ) : (
                          (row.classes && row.classes.length) ? (
                            <li className="dropdown" key={`c-sec-${index}`}>
                              <Link to={'#'} title={row.name}>
                                <span>{row.name}</span> <i className="bi bi-chevron-right"></i>
                              </Link>
                              {(row.classes) && (row.classes.length) ? (
                                <ul>
                                  {(row.classes) && row.classes.map((row1, index1) => (
                                    <li key={`c-third-${index1}`}>
                                      <Link to={row1.url} title={row1.name}>
                                        <span>{row1.name}</span>
                                      </Link>
                                    </li>
                                  )
                                  )}
                                </ul>
                              ) : ''}
                            </li>
                          ) : (
                            <li key={`c-first-${index}`}>
                              <Link to={row.slug} title={row.name}>
                                <span>{row.name}</span>
                              </Link>
                            </li>
                          )
                        )
                      )
                      )
                    }
                  </ul>
                ) : ''}

              </li>
              {/* <li><Link className={(currentPath === "/central-exams/upsc") ? "nav-link scrollto active" : "nav-link scrollto"} to={"/central-exams/upsc"}>Exams</Link></li> */}
              <li><Link className={(currentPath === "/contact-us") ? "nav-link scrollto active" : "nav-link scrollto"} to={"/contact-us"}>Contact Us</Link></li>
              {/* <li><Link className={(currentPath === "/login") ? "nav-link scrollto active" : "nav-link scrollto"} to={"/login"}>Login</Link></li> */}
              {user ? (
                <>
                  <li>
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={open ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                    >
                      <Avatar className="header__profile" src={user.profile_image} alt={user.first_name}>{user.first_name}</Avatar>
                    </IconButton>
                  </li>
                  <Menu
                    className="header__muiMenu"
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                          width: 26,
                          height: 26,
                          mr: 1,
                        },
                        '&:before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    {/* <MenuItem onClick={() => navigate('/dashboard')}>
                      <ListItemIcon>
                        <DashboardIcon />
                      </ListItemIcon>
                      <div className="header__profileMenu">
                        <span>Dashboard</span>
                      </div>
                    </MenuItem>
                    <MenuItem onClick={() => navigate('/dashboard/order/order-history')}>
                      <ListItemIcon>
                        <ViewListIcon />
                      </ListItemIcon>
                      <div className="header__profileMenu">
                        <span>My Orders</span>
                      </div>
                    </MenuItem> */}
                    <MenuItem onClick={logout}>
                      <ListItemIcon>
                        <LogoutIcon fontSize="medium" />
                      </ListItemIcon>
                      <div className="header__profileMenu">
                        <span>Sign Out</span>
                      </div>
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <li><Link className="nav-link getstarted scrollto" to="#" onClick={() => handleOpen(false)}><span>Login</span></Link></li>
              )}
            </ul>
          </nav>
          {/* .navbar  */}
          <a href={'https://play.google.com/store/apps/details?id=com.bhartieducation'} target='_blank' className="playstore-link" title='GooglePlayStore Logo'>
            <img src={`${process.env.PUBLIC_URL}/assets/frontend/img/google-play-store.png`} className="google-playstore-image" alt="GooglePlayStore Logo" />
          </a>
          <div className="social-links">
            <a href={'https://www.facebook.com/invictaa1'} target='_blank' className="facebook" title='Facebook Logo'>
              <FacebookIcon />
            </a>
            <a href={'https://instagram.com/invictaa1?igshid=OGQ5ZDc2ODk2ZA=='} target='_blank' className="instagram" title='Facebook Logo'><InstagramIcon /></a>
            <a href={'https://www.linkedin.com/company/invictaa/'} target='_blank' className="linkedin" title='Linkedin Logo'><LinkedInIcon /></a>
            <a href={'https://www.youtube.com/channel/UCkpLlu03SQCvK-wNW9YLOqg'} target='_blank' className="youtube" title='Youtube Logo'><YouTubeIcon /></a>
            <a href={'https://play.google.com/store/apps/details?id=com.bhartieducation'} target='_blank' className="playstore" title='Play Store Logo'><ShopTwoIcon /></a>
          </div>
        </div >

        {/* <Medium /> */}
      </header >
      {/* End Header  */}

      {
        (currentPath === "/") ? (
          <>
            <section id="hero" className="d-flex align-items-center" style={{ position: 'relative' }}>
              <div className="left-circle"></div>
              <div className="left-dot"></div>
              <div className="right-dot"></div>
              <div className="bg-right"></div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                    <h1 style={{ fontSize: '39px' }}>India’s Biggest E-learning & Evaluation Platform With More Than 1 Million Question</h1>
                    <p>An education system that contributes to an equitable and vibrant knowledge society, by providing high-quality education to all.</p>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2 hero-img text-center" data-aos="zoom-in" data-aos-delay="200">
                    <img src={`${process.env.PUBLIC_URL}/assets/frontend/img/top-banner.png`} className="img-fluid animated" alt="Banner Image" />
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : ''
      }
    </>
  );
};

export default Header;
