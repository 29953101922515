import React from 'react';
import { Link } from 'react-router-dom';
import Header from './../Header/Header';
import Footer from './../Footer/Footer';
import OurTeams from './../HomePage/OurTeams';
import Testimonials from './../HomePage/Testimonials';




function RefundCancellation() {
  return (
    <>
      <Header />
      <section id="hero" className="d-flex align-items-center inhead">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center" data-aos="fade-up" data-aos-delay="200">
              <h1>Refund & Cancellation</h1>
            </div>
          </div>
        </div>
      </section>
      <main id="main">
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <ol>
              <li><Link to="/" title='Home'>Home</Link></li>
              <li>Refund & Cancellation</li>
            </ol>
          </div>
        </section>
        <br />
        <div className="main_content info-page">
          <div className="section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row align-items-center prod-detail">
                    <div className="col-12">
                      <h1 className="btn-shine text-center">Refund & Cancellation</h1>
                      <h3><i>Refund & Cancellation</i></h3>
                      <p>When you buy our products/services, your purchase is not entitled to any refund. If you buy any online batch/service, it is non-refundable. If you purchase any batch by mistake, you can request to change it to another batch of the same amount within 10 days of the purchase. We recommend you first check the complete system and then decide to make a payment in the case of books. If the product received is damaged/lost by the courier partner, the student is entitled to a replacement. However, in case of the wrong address is given or books are not accepted by students the purchase will not be entitled to any kind of refund.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <OurTeams />

        <Testimonials />
        <Footer />
      </main>
    </>
  );
}
export default RefundCancellation;
