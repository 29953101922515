import React, { useEffect, useState } from 'react';

import { useNavigate, Link, useParams } from 'react-router-dom';
import Header from './../Header/Header';
import Footer from './../Footer/Footer';
import * as Yup from 'yup';
import {
  Alert, Backdrop, CircularProgress, Grid, Button
} from '@mui/material';
import axios from 'axios';

const ClassGroupDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [class_group_slug, setClassGroupSlug] = useState(params.class_group_slug);
  const [loading, setLoading] = useState(false);
  const [class_group, setClassGroup] = useState(false);
  const [tableData, setTableData] = useState(false);
  useEffect(() => {
    fetchData()
  }, []);

  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/frontend/get-class-group-classes', {
          class_group_slug: class_group_slug
        })
          .then(response => {
            if (response.data.response_code === 200) {
              setClassGroup(response.data.data.class_group)
              setTableData(response.data.data.classes);
            } else if (response.data.response_code === 401) {

            } else {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  const classLayoutUrl = (row) => {
    var url = '';
    if (row.is_test_series || row.is_pyp) {
      url = `/${class_group.slug}/class/includes/${row.slug}`;
    } else {
      url = `/${class_group.slug}/class/subjects/${row.slug}`;
    }
    return url;
  }

  return (
    <>
      <Header />
      <Backdrop
        sx={{ color: '#015998', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={setLoading}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
      {(class_group) ? (
        <>
          <section id="hero" className="d-flex align-items-center inhead">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center" data-aos="fade-up" data-aos-delay="200">
                  <h1>{class_group.name}</h1>
                </div>
              </div>
            </div>
          </section>
          <main id="main">
            <section id="breadcrumbs" className="breadcrumbs">
              <div className="container">
                <ol>
                  <li><Link to="/" title='Home'>Home</Link></li>
                  <li>{class_group.name}</li>
                </ol>
              </div>
            </section>
            {tableData && (
              <section className="onecol listing-page">
                <div className="container" data-aos="fade-up">
                  <Grid container spacing={3}>
                    {
                      tableData.map((row, index) => (
                        <Grid item sm={6} xs={6} lg={4} key={index}>
                          <Link to={classLayoutUrl(row)} title={row.class_name}>
                            <div className="boximg">
                              <span className='box-title'>{row.class_name}</span>
                              <div className="img-box">
                                {(row.thumb_nail_image) ? (
                                  <img src={row.thumb_nail_image} title={row.class_name} alt={row.class_name} className='class-thumb' />
                                ) : (
                                  <img title={row.class_name} alt={row.class_name} src={`${process.env.PUBLIC_URL}/assets/frontend/img/jee.jpg`} className='class-thumb' />
                                )}
                              </div>
                              <p className="lightred">{row.description}</p>
                            </div>
                          </Link>
                        </Grid>
                      ))
                    }
                  </Grid>
                </div>
              </section>
            )}
          </main >
        </>
      ) : (
        <>
          <section id="hero" className="d-flex align-items-center inhead">
            <div className="container">
            </div>
          </section>
          {(!loading) && (
            <main id="main">
              <section className="onecol listing-page">
                <div className="container" data-aos="fade-up">
                  <h1 style={{ fontSize: '125px', color: '#3c0a8d', fontFamily: 'inherit', fontWeight: '600' }}>404 Page</h1>
                  <p style={{ fontSize: '20px' }}>We con`t find what you are looking for.</p>
                  <Link to={'/'} className="lightbtn" title='Go to Home Page'>Go to Home Page</Link>
                </div>
              </section>
            </main >
          )}
        </>
      )}
      <Footer />
    </>
  );
};

export default ClassGroupDetails;
