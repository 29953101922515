import React, { useReducer, createContext, useEffect, useContext, ReactNode, useState } from "react";
import axios from 'axios';
import swal from 'sweetalert';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
// import logo from 'https://invictaa.com/assets/frontend/img/logo.png';
// import useAuth from 'app/contexts/AuthContext';

const CartStateContext = createContext({
  getItemQuantity: 0,
  increaseCartQuantity: '',
  decreaseCartQuantity: '',
  removeFromCart: '',

});
// let { isAuthenticated, user } = useAuth();
// 
export function useCart() {
  return useContext(CartStateContext);
}

const CartProvider = ({ children }) => {
  // let { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const params = useParams();
  const [slug, setSlug] = useState(params.slug);
  const [course_exam_id, setCourseExamId] = useState(false);
  const [course, setCourse] = useState(params.course);
  const [loading, setLoading] = useState(false);
  const [isMiniCartOpen, setIsMiniCartOpen] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [cart, setCart] = useState(false);
  const [order, setOrder] = useState(false);
  const [coupon, setCoupon] = useState(false);
  const [isCouponApply, setIsCouponApply] = useState(false);
  const openMiniCart = () => setIsMiniCartOpen(true);
  const closeMiniCart = () => setIsMiniCartOpen(false);

  useEffect(() => {
    /**
     * Get Course details of current slug.
     * @author Pavan Ji <dropmail2pavan@gmail.com>
     */
    if (currentPath.includes("order-summary") || currentPath.includes("order-placed") || currentPath.includes("order-success")) {

      if (params.slug && params.course) {
        // console.log('params', params);
        // console.log('params.slug', params.slug);
        // console.log('slug', slug);
        setSlug(params.slug);
        setCourse(params.course);
        if (!cartItems.length) {
          fetchCartData(params.slug, params.course);
        }
      }
    }

  }, [params]);

  /**
   * Fetch cart data.
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const fetchCartData = async (currentSlug, currentCourse) => {
    setLoading(true);
    try {
      axios.post('/api/dashboard/get-cart-detail', {
        'slug': currentSlug,
        'course': currentCourse,
      })
        .then(response => {
          if (response.data.response_code === 200) {
            setCartItems(response.data.data);
          } else if (response.data.response_code === 401) {
            // navigate('/dashboard')
          } else {
            // navigate('/dashboard')
          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });

      axios.post('/api/dashboard/get-cart-summary', {
        'slug': currentSlug,
        'course': currentCourse,
      })
        .then(response => {
          if (response.data.response_code === 200) {
            setCart(response.data.data);
          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
    } catch (e) {
      setLoading(false);
    }
  }
  // useEffect(() => {

  //   /**
  //    * Check User logged IN.
  //    * @author Pavan Ji <dropmail2pavan@gmail.com>
  //    */
  //   if (isAuthenticated) {
  //     fetchCartData();
  //   }

  // }, [isAuthenticated]);

  /**
   * Fetch cart data.
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  // const fetchCartData = async () => {
  //   setLoading(true);
  //   try {
  //     await axios.get('/sanctum/csrf-cookie').then(response => {
  //       axios.post('/api/frontend/get-user-cart')
  //         .then(response => {
  //           if (response.data.response_code === 200) {
  //             setCartItems(response.data.data);
  //           } else if (response.data.response_code === 401) {

  //           } else {

  //           }
  //           setLoading(false);
  //         })
  //         .catch(function (error) {
  //           setLoading(false);
  //           console.log(error);
  //         });
  //       axios.post('/api/frontend/get-cart-summary')
  //         .then(response => {
  //           if (response.data.response_code === 200) {
  //             setCart(response.data.data);
  //           } else if (response.data.response_code === 401) {

  //           } else {

  //           }
  //           setLoading(false);
  //         })
  //         .catch(function (error) {
  //           setLoading(false);
  //           console.log(error);
  //         });
  //     });
  //   } catch (e) {
  //     setLoading(false);
  //   }
  // }

  /**
   * Cart Items.
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */

  const totalCartItems = cartItems.length || 0;

  /**
   * Cart Quantity.
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const cartQuantity = cartItems.reduce(
    (quantity, item) => item.quantity + quantity, 0
  );

  /**
   * Increase Cart Quantity.
   * @param {*} id  // Product ID
   * @param {*} quantity  // Product quantity
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  function increaseCartQuantity(id, quantity) {

    try {
      setLoading(true);
      axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/frontend/update-user-cart', {
          row_id: id,
          quantity: quantity,
        })
          .then(response => {
            if (response.data.response_code === 200) {
              fetchCartData();
              swal({
                title: "Good job!",
                text: response.data.message,
                icon: "success",
              });
            } else if (response.data.response_code == 504) {
            } else {
              swal({
                title: "Warning!",
                text: response.data.message,
                icon: "warning",
              });
            }
            setLoading(false);
          })
          .catch(function (error) {
            console.error(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * Decrease Cart Quantity.
   * @param {*} id  // Product ID
   * @param {*} quantity  // Product quantity
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  function decreaseCartQuantity(id, quantity) {
    setCartItems(currentItems => {
      if (currentItems.find(item => item.id === id)?.quantity === 1) {
        return currentItems.filter(item => item.id !== id);
      } else {
        return currentItems.map(item => {
          if (item.id === id) {
            return { ...item, quantity: item.quantity - quantity };
          } else {
            return item;
          }
        })
      }
    })
  }

  /**
   * Remove Product item from cart.
   * @param {*} id  // Product ID
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const removeItemFromCart = (id) => {
    try {
      setLoading(false);
      axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/frontend/remove-product-from-cart', {
          row_id: id,
        })
          .then(response => {
            if (response.data.response_code === 200) {
              fetchCartData();
              swal({
                title: "Good job!",
                text: response.data.message,
                icon: "success",
              });
            } else if (response.data.response_code == 504) {
            } else {
              swal({
                title: "Warning!",
                text: response.data.message,
                icon: "warning",
              });
            }
            setLoading(false);
          })
          .catch(function (error) {
            console.error(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * Load Razorpay SDK.
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  /**
   * Display Razorpay.
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  async function displayRazorpay(result) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    const { amount, order_id, currency } = result;

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: amount.toString(),
      currency: currency,
      name: "Bharti Education",
      description: result.description,
      image: `${process.env.PUBLIC_URL}/assets/backend/images/logo.png`,
      order_id: order_id,
      handler: async function (pay_response) {
        axios.post('/api/dashboard/order-payment', {
          order_creation_id: order_id,
          razorpay_payment_id: pay_response.razorpay_payment_id,
          razorpay_order_id: pay_response.razorpay_order_id,
          razorpay_signature: pay_response.razorpay_signature,
          payment_status: 'success'
        })
          .then(response => {
            if (response.data.response_code === 200) {
              if (response.data.data.order_id) {
                getOrder(response.data.data.order_id);
                setCartItems([]);
                swal({
                  text: response.data.message,
                  icon: "success",
                  timer: 2000,
                });
              } else {
              }

            } else {
            }
            setLoading(false);
          })
          .catch(function (error) {
            console.error(error);
            setLoading(false);
          });
      },
      "modal": {
        "ondismiss": function () {
          axios.post('/api/dashboard/order-payment', {
            order_creation_id: order_id,
            razorpay_order_id: order_id,
            payment_status: 'cancel'
          })
            .then(response => {
              if (response.data.response_code === 200) {
                getOrder(response.data.data.order_id);
                setCartItems([]);
                swal({
                  text: response.data.message,
                  icon: "success",
                  timer: 2000,
                });
              } else {
              }
              setLoading(false);
            })
            .catch(function (error) {
              console.error(error);
              setLoading(false);
            });
        }
      },
      prefill: {
        name: result.name,
        email: result.email,
        contact: result.contact_number,
      },
      theme: {
        color: "#420e97",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();

    /**
     * Handle failed paymet request
     */
    paymentObject.on('payment.failed', function (pay_response) {
      axios.post('/api/dashboard/order-payment', {
        order_creation_id: order_id,
        razorpay_payment_id: pay_response.error.metadata.payment_id,
        razorpay_order_id: pay_response.error.metadata.order_id,
        payment_status: 'failed'
      })
        .then(response => {
          if (response.data.response_code === 200) {
          } else {
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.error(error);
          setLoading(false);
        });
    });

  }

  /**
   * Place order of selected products in to a Cart.
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const orderPlaced = () => {
    setLoading(true);
    try {
      axios.post('/api/dashboard/create-order', {
        'coupon': coupon,
        'slug': slug,
        'course': course,
      })
        .then(response => {
          if (response.data.response_code === 200) {
            if (response.data.data.razor_order && response.data.data.razor_order !== undefined) {
              if (response.data.data.razor_order.order_id && response.data.data.razor_order.amount) {
                //Call Razorpay Payment Getway
                displayRazorpay(response.data.data.razor_order);
              } else {
                getOrder(response.data.data.order_id);
                setCartItems([]);
                swal({
                  text: response.data.message,
                  icon: "success",
                  timer: 2000,
                });
              }
            } else {
              getOrder(response.data.data.order_id);
              setCartItems([]);
              swal({
                text: response.data.message,
                icon: "success",
                timer: 2000,
              });
            }
          } else {
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.error(error);
          setLoading(false);
        });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  /**
   * Get Order detail from server 
   * @param {*} order_id 2103%23HEGAN-202333
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const getOrder = (order_id) => {
    setLoading(true);
    try {
      if (order_id) {
        axios.post('/api/dashboard/get-order-details', {
          order_id: order_id
        })
          .then(response => {
            if (response.data.response_code === 200) {
              setOrder(response.data.data);
              navigate(`/dashboard/checkout/class/${slug}/order-success/` + encodeURIComponent(order_id));
            } else {
            }
            setLoading(false);
          })
          .catch(function (error) {
            console.error(error);
            setLoading(false);
          });
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  /**
   * Get Order detail from server 
   * @param {*} order_id 2103%23HEGAN-202333
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const applyCoupon = () => {
    setLoading(true);
    try {
      // if (coupon) {
      axios.post('/api/dashboard/apply-coupon', {
        'coupon': coupon,
        'slug': slug,
        'course': course,
      })
        .then(response => {
          if (response.data.response_code === 200) {
            setCart(response.data.data);
            if (response.data.data.coupon_detail.status) {
              setIsCouponApply(true);
              swal({
                text: response.data.data.coupon_detail.message,
                icon: "success",
                timer: 2000,
              });
            } else {
              setCoupon(false);
              swal({
                text: `'${response.data.data.coupon_detail.coupon}'  ${response.data.data.coupon_detail.message}`,
                icon: "error",
                timer: 2000,
              });
            }

          } else {
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.error(error);
          setLoading(false);
        });
      // } else {
      //   swal({
      //     text: 'Please enter valid coupon code!',
      //     icon: "warning",
      //     timer: 2000,
      //   });
      // }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }



  /**
   * Get Order detail from server 
   * @param {*} order_id 2103%23HEGAN-202333
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */

  const removeCoupon = () => {
    setLoading(true);
    try {
      axios.post('/api/dashboard/remove-coupon', {
        'slug': slug,
        'course': course,
      })
        .then(response => {
          if (response.data.response_code === 200) {
            setCoupon(false);
            setCart(response.data.data);
            swal({
              text: 'Coupon removed successfully!',
              icon: "success",
              timer: 2000,
            });
          } else {
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.error(error);
          setLoading(false);
        });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  /**
   * Rander Cart Provider
   * @param {*} order_id 2103%23HEGAN-202333
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  return (
    <CartStateContext.Provider value={{
      openMiniCart,
      closeMiniCart,
      cart,
      totalCartItems,
      cartQuantity,
      increaseCartQuantity,
      decreaseCartQuantity,
      removeItemFromCart,
      orderPlaced,
      order,
      getOrder,
      applyCoupon,
      removeCoupon,
      setCoupon,
      coupon,
      isCouponApply
    }}>
      {children}
    </CartStateContext.Provider>

  );
};

export default CartProvider;