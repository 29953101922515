import React, { useEffect } from 'react';

import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import ScrollToTop from './components/ScrollToTop';
// import { AuthProvider } from './contexts/JWTAuthContext';
import AuthProvider from './contexts/AuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import MediumProvider from './contexts/MediumContext';
import { Store } from './redux/Store';
import routes from './routes';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_PATH;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';

axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config) {
  const profile = JSON.parse(localStorage.getItem('profile'));
  if (profile !== undefined && profile !== null) {
    const token = profile.access_token;
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  }
  return config;
});

const App = () => {
  const content = useRoutes(routes);
  useEffect(() => {
    // prevents the default right-click menu from appearing
    function handleContextMenu(e) {
      e.preventDefault(); // prevents the default right-click menu from appearing
    }
    // add the event listener to the component's root element
    const rootElement = document.getElementById('root');
    rootElement.addEventListener('contextmenu', handleContextMenu);
    // remove the event listener when the component is unmounted

    return () => {
      rootElement.removeEventListener('contextmenu', handleContextMenu);
    };

    // End Right click desable

  }, []);
  return (
    <Provider store={Store}>
      <SettingsProvider>
        <MatxTheme>
          <MediumProvider>
            <AuthProvider>
              <ScrollToTop>
                {content}
              </ScrollToTop>
            </AuthProvider>
          </MediumProvider>
        </MatxTheme>
      </SettingsProvider>
    </Provider>
  );
};

export default App;
