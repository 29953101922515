import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import {
  Button, Backdrop, CircularProgress, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, IconButton
} from "@mui/material";
import { MathJax } from "better-react-mathjax";
import Latex from "react-latex";
import ReactHtmlParser from 'react-html-parser';
import { useAuth } from './../../../contexts/AuthContext';
import Header from './../Header/Header';
import Footer from './../Footer/Footer';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import ImagePreviewDialog from './ImagePreviewDialog';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

function ExamPreviousYearQuestionQuiz() {
  const params = useParams();
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth();
  const [loading, setLoading] = useState(false);
  const [quiz_key, setQuizKey] = useState(params.quiz_key);
  const [quiz, setQuiz] = useState(false);
  const [quizQuestions, setQuizQuestions] = useState(false);

  const [activeQuestion, setActiveQuestion] = useState(0)

  const [question, setQuestion] = useState(null)
  const [question_type, setQuestionType] = useState(null)
  const [before_title_question_image, setBeforeTitleQuestionImage] = useState(null)
  const [question_title, setQuestionTitle] = useState(null)
  const [question_image, setQuestionImage] = useState(null)

  const [options, setOptions] = useState(null)
  const [correctOption, setCurrectOption] = useState('');
  const [selectedAnswer, setSelectedAnswer] = useState(false);

  const [rememberOpen, setRememberOpen] = useState(false);
  const [answerOpen, setAnswerOpen] = useState(false);

  const [result, setResult] = useState({
    score: 0,
    correctAnswers: 0,
    wrongAnswers: 0,
    questions: []
  })

  const [submitQuiz, setSubmitQuiz] = useState(false);
  const [imagePreviewModalOpen, setImagePreviewModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(false);
  const [countdown, setCountdown] = useState({
    expirityTime: 0,
    expired: false
  });
  const [focus, setFocus] = useState(true)
  const [timer, setTimer] = useState();
  const [timeOver, setTimeOver] = useState(false);

  // useEffect(() => {
  //   if (quizQuestions) {
  //     let time = countdown.expirityTime;
  //     if (time > 0 && (focus)) {
  //       const timerId = setTimeout(() => {
  //         setCountdown({ expirityTime: time - 1 });
  //       }, 1000);
  //       return () => clearTimeout(timerId);
  //     }
  //     if (time === 0 && (focus)) {
  //       setTimeOver(true);
  //       if (timeOver) {
  //         swal({
  //           title: "Time is Over",
  //           text: "Submit you quiz!",
  //           icon: "success",
  //           buttons: [
  //             false,
  //             'Submit Quiz'
  //           ],
  //           successMode: true,
  //         }).then(function (isConfirm) {
  //           if (isConfirm) {
  //             handleQuizSubmit();
  //           }
  //         });
  //       }
  //     }
  //   }
  // });

  /**
   * Default effect first load and start quiz.
   */
  useEffect(() => {
    if (quiz_key) {
      fetchQuiz();
    }
  }, []);

  /**
   * Fetching data from server.
   */
  const fetchQuiz = async () => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        var api_url = '/api/frontend/get-exam-subject-pyq-quiz-questions';
        if (isAuthenticated) {
          api_url = '/api/frontend/auth/get-exam-subject-pyq-quiz-questions';
        }
        axios.post(api_url, { 'quiz_key': quiz_key })
          .then(response => {
            if (response.data.response_code === 200) {
              if (response.data.data.quiz.status === '1' || response.data.data.quiz.status === 1) {
                navigate(`/`);
                swal({
                  title: "Completed",
                  text: "You have already completed this quiz!",
                  icon: "success",
                  timer: 2500,
                })

              } else {
                setQuiz(response.data.data.quiz)
                if (response.data.data.quiz_questions.length) {
                  setQuizQuestions(response.data.data.quiz_questions);
                  // let time = response.data.data.quiz_questions.length * 60;
                  // setCountdown({ expirityTime: time });
                  // if (time > 0 && (focus)) {
                  //   const timerId = setTimeout(() => {
                  //     setCountdown({ expirityTime: time - 1 });
                  //   }, 1000);
                  //   return () => clearTimeout(timerId);
                  // }
                } else {
                  navigate(`/`);
                  swal({
                    title: "No Questions selected for the quiz",
                    text: "Please try again later!",
                    icon: "warning",
                    timer: 2500,
                  })
                }
              }
            } else if (response.data.response_code === 401) {

            } else {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * Effect start a quiz with first question.
   */
  useEffect(() => {
    if (quizQuestions) {
      // Fill All question to result
      quizQuestions.map((qst, idx) => {
        var resultOptions = {
          'question_id': qst.id,
          'answer_option_id': false,
          'correct_option_id': false,
          'is_correct': 0,
        };
        setResult((prev) => (
          { ...prev, questions: [...prev.questions, resultOptions] }
        )
        );
      })

      setQuestion(quizQuestions[activeQuestion]);
      setQuestionType(quizQuestions[activeQuestion].question_type);
      setBeforeTitleQuestionImage(quizQuestions[activeQuestion].before_title_question_image);
      setQuestionTitle(quizQuestions[activeQuestion].question_title);
      setQuestionImage(quizQuestions[activeQuestion].question_image);
      setOptions(quizQuestions[activeQuestion].options);
      quizQuestions[activeQuestion].options.map((opt, key) => {
        if (opt.is_correct) {
          setCurrectOption(opt.id);
        }
      }
      )
      setLoading(false);
    } else {
      // navigate(`/`);
    }
  }, [quizQuestions]);

  /**
   * This Use effect is used to change question next or previos after result update.
   */
  useEffect(() => {
    // console.log(result, result);
    if (quizQuestions) {
      setQuestion(quizQuestions[activeQuestion]);
      setQuestionType(quizQuestions[activeQuestion].question_type);
      setBeforeTitleQuestionImage(quizQuestions[activeQuestion].before_title_question_image);
      setQuestionTitle(quizQuestions[activeQuestion].question_title);
      setQuestionImage(quizQuestions[activeQuestion].question_image);
      setOptions(quizQuestions[activeQuestion].options);
      quizQuestions[activeQuestion].options.map((opt, key) => {
        if (opt.is_correct) {
          setCurrectOption(opt.id);
        }
      }
      )
      setLoading(false);
    } else {
      // navigate(`/`);
    }
    if (submitQuiz) {
      handleQuizSubmit();
    }
  }, [result]);

  /**
   * On Question change check previous answer if given.
   */
  useEffect(() => {
    if (quizQuestions) {
      if (result.questions.find((rowValue) => rowValue.question_id === question.id) !== undefined) {
        const persistent_result = result.questions.filter((rowValue) => rowValue.question_id === question.id);
        setSelectedAnswer(persistent_result[0].answer_option_id);
      }
      setLoading(false);
    }
  }, [question]);

  /**
  * This function is used select answer on option click.
  */
  const onAnswerSelected = (question_id, selected_option_id) => {
    if (!selectedAnswer) {
      setSelectedAnswer(selected_option_id);
      if (correctOption === selected_option_id) {
        setRememberOpen(false);
        setAnswerOpen(false);
      } else {
        setRememberOpen(true);
        setAnswerOpen(true);
      }
    }
  }

  /**
  * This function is used to iterate next question.
  */
  const onClickNext = () => {
    setLoading(true);

    // Update result 
    _fn_result_store_update();

    // again reset the selectedAnwerIndex, so it won't effect next question
    setSelectedAnswer(false)
    if (activeQuestion < quizQuestions.length - 1) {
      setActiveQuestion((prev) => prev + 1)
    } else {
      setSubmitQuiz(true);
    }

  }

  /**
  * This function is used to iterate previous question.
  */
  const onClickPrev = () => {
    setLoading(true);
    // Update result 
    _fn_result_store_update();

    // again reset the selectedAnwerIndex, so it won't effect next question
    setSelectedAnswer(false)
    if (activeQuestion > 0) {
      setActiveQuestion((prev) => prev - 1)
    }
  }

  const handleQuestionChange = (question_no) => {
    setLoading(true);
    // Update result 
    _fn_result_store_update();
    // again reset the selectedAnwerIndex, so it won't effect next question
    setSelectedAnswer(false)
    if (question_no >= 0) {
      setActiveQuestion(question_no);
    }
  }

  /**
   * Answer visibility set on option or question chnage.
   */
  useEffect(() => {
    if (correctOption) {
      setRememberOpen(false);
      setAnswerOpen(false);
      if (result.questions[activeQuestion].answer_option_id) {
        if (result.questions[activeQuestion].is_correct) {
          setRememberOpen(false);
          setAnswerOpen(false);
        } else {
          setRememberOpen(true);
          setAnswerOpen(true);
        }
      }
      setLoading(false);
    }

  }, [correctOption]);
  /**
  * This function is used to store/update result local variable.
  */
  const _fn_result_store_update = () => {
    try {
      var currentQuestionOptions = {
        'question_id': question.id,
        'answer_option_id': selectedAnswer,
        'correct_option_id': correctOption,
        'is_correct': (selectedAnswer === correctOption) ? 1 : 0,
      };

      const ans_index = result.questions.findIndex((rowValue) => rowValue.question_id === question.id);
      var updated_question_answer_option = [...result.questions];

      var ans_currect_wrang = 0;
      var your_score = 0;
      if (ans_index !== -1 || ans_index !== '-1') {
        const persistent_result = result.questions.filter((rowValue) => rowValue.question_id === question.id);
        if ((!persistent_result[0].answer_option_id) && selectedAnswer) {
          ans_currect_wrang = 1;
          your_score = 1;
        }
        updated_question_answer_option.splice(ans_index, 1, currentQuestionOptions)
      } else {
        updated_question_answer_option.push(currentQuestionOptions);
        ans_currect_wrang = 1;
        your_score = 1;
      }

      setResult((prev) =>
        (selectedAnswer === correctOption)
          ? {
            ...prev,
            score: prev.score + your_score,
            correctAnswers: prev.correctAnswers + ans_currect_wrang,
            questions: updated_question_answer_option
          }
          : {
            ...prev,
            wrongAnswers: prev.wrongAnswers + ans_currect_wrang,
            questions: updated_question_answer_option
          }
      )
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  /**
  * This function is used to submit quiz data.
  */
  const handleQuizSubmit = () => {
    try {
      setLoading(true);
      axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/frontend/save-exam-quiz-score', {
          quiz_key: quiz_key,
          result: result,
        })
          .then(response => {
            if (response.data.response_code === 200) {
              swal({
                text: response.data.message,
                icon: "success",
                timer: 1500,
              });
              navigate(`/exam/quiz-result/${quiz_key}`);
            }
            setLoading(false);
          })
          .catch(function (error) {
            console.error(error);
          });
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  /**
  * This function is used to finish Quiz and submit.
  */
  const handleFinishQuiz = () => {
    swal({
      title: "Are you sure?",
      text: "You want to finish this quiz!",
      icon: "warning",
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        try {
          setSubmitQuiz(true);
          onClickNext();
          // handleQuizSubmit();
        } catch (e) {
          setLoading(false);
        }
      }
    });
  }

  /**
  * This function is used to check question is attempted or not not.
  */
  const is_question_attempted = (qust_id) => {
    if (result.questions.find((rowValue) => rowValue.question_id === qust_id) !== undefined) {
      const persistent_result = result.questions.filter((rowValue) => rowValue.question_id === qust_id);
      return (persistent_result[0].answer_option_id) ? true : false;
    } else {
      return false;
    }
  }
  /**
    * This function is used to transform string to Latex.
    */
  function transform(node, index) {
    if (node.type === 'tag') {
      return node.data;
    } else {
      return <Latex displayMode={true} trust={true} key={index}>{node.data}</Latex>;
    }
  }

  const PreviewImage = (image_url) => {
    if (image_url) {
      setImageUrl(image_url);
      setImagePreviewModalOpen(true);
    }
  }
  return (
    <>
      <Header />
      <Backdrop
        sx={{ color: '#015998', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={setLoading}
      >
        <CircularProgress color="secondary" />
      </Backdrop>

      <main id="main">
        <br /> <br />
        <section className="inner-page quiz-page">
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-12">
                    <div className="timer"> </div>
                  </div>
                </div>
                <form>
                  <div className="row">
                    {quizQuestions && (
                      <>
                        <ImagePreviewDialog imagePreviewModalOpen={imagePreviewModalOpen} setImagePreviewModalOpen={setImagePreviewModalOpen} imageUrl={imageUrl} />
                        <div className="col-md-12">
                          <div className="queststyle">
                            <div className='question-number'>{`${(activeQuestion + 1)}.  `} </div>{` `}
                            {(before_title_question_image) ? (
                              <>
                                <div className='behore-image'>
                                  <IconButton aria-label="Preview" className='image-preview-icon-left' onClick={() => PreviewImage(before_title_question_image)}>
                                    <FullscreenIcon />
                                  </IconButton>
                                  <img src={before_title_question_image} title={question_title} alt={question_title} className='question-image' />
                                </div>
                              </>
                            ) : ''
                            }
                            <div className='question-title'>
                              <MathJax>
                                {question_title && (ReactHtmlParser(question_title, { transform }))}
                              </MathJax>
                            </div>
                            {(question_image) ? (
                              <>
                                <IconButton aria-label="Preview" className='image-preview-icon-left' onClick={() => PreviewImage(question_image)}>
                                  <FullscreenIcon />
                                </IconButton>
                                <img src={question_image} title={question_title} alt={question_title} className='question-image' />
                              </>
                            ) : ''
                            }
                            {(() => {
                              switch (question_type) {
                                case 'radio':
                                  return (
                                    <div className="inputform1">
                                      {options && (
                                        <FormControl>
                                          <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            name="radio-buttons-group"
                                          >
                                            <Grid style={{ width: '100% !important' }} container spacing={5}>
                                              {
                                                options.map((option, opt_index) => (
                                                  <Grid item sm={12} xs={12} lg={6} key={opt_index} sx={{ position: 'relative !important;' }}>
                                                    {/* <Button
                                                      onClick={() => onAnswerSelected(option.question_id, option.id)}
                                                      key={opt_index}
                                                      variant={"outlined"}
                                                      className="quiz-opt"
                                                      disabled={(selectedAnswer) ? ((selectedAnswer === option.id) ? false : true) : false}
                                                      sx={{ width: '100% !important', height: '100% !important', justifyContent: 'left' }}
                                                    > */}
                                                    {(option.option_image) ? (
                                                      <IconButton aria-label="Preview" className='image-preview-icon' onClick={() => PreviewImage(option.option_image)}>
                                                        <FullscreenIcon />
                                                      </IconButton>

                                                    ) : ''
                                                    }
                                                    <Button
                                                      onClick={() => onAnswerSelected(option.question_id, option.id)}
                                                      key={opt_index}
                                                      variant={(selectedAnswer) ? ((selectedAnswer === option.id) ? 'contained' : ((correctOption === option.id) ? 'contained' : 'outlined')) : 'outlined'}
                                                      color={(selectedAnswer) ? ((selectedAnswer === correctOption) ? 'success' : ((correctOption === option.id) ? 'success' : 'error')) : 'primary'}
                                                      // color={((selectedAnswer === option.id)) ? ((selectedAnswer === correctOption) ? "success" : "error") : "primary"}
                                                      className="quiz-opt"
                                                      disabled={(selectedAnswer) ? ((selectedAnswer === option.id) ? false : ((correctOption === option.id) ? false : true)) : false}
                                                      sx={{ width: '100% !important', height: '100% !important', justifyContent: 'left', padding: '5px 10px 5px 42px' }}
                                                    >
                                                      <FormControlLabel value={option.id}
                                                        checked={(selectedAnswer === option.id) ? true : false}
                                                        control={<Radio />}
                                                        label={
                                                          <>
                                                            {(option.option_image) ? (
                                                              <img src={option.option_image} className="option-image" />
                                                            ) : ''
                                                            }
                                                            <MathJax>
                                                              {option.option_value && (
                                                                <Latex displayMode={true}>{option.option_value && option.option_value.replace("%25", '%').toString()}</Latex>
                                                              )}
                                                            </MathJax>
                                                          </>
                                                        } />
                                                    </Button>
                                                  </Grid>
                                                )
                                                )
                                              }
                                            </Grid>
                                          </RadioGroup>
                                        </FormControl>
                                      )
                                      }
                                    </div>
                                  )
                                default:
                                  return null
                              }
                            })()}
                          </div>
                        </div>
                        <div className="text-center">
                          <br /><br />
                          <Button onClick={onClickPrev} size='small' variant="contained" className="lightbtn">
                            Previous
                          </Button>
                          <Button onClick={onClickNext} size='small' variant="contained" className="lightbtn">
                            {activeQuestion === quizQuestions.length - 1 ? 'Finish Quiz' : 'Next'}
                          </Button>
                          <br /><br />
                        </div>
                        {selectedAnswer && (
                          <>
                            {rememberOpen && (
                              <>
                                {(question.remember_text || question.remember_image || question.remember_video_link) && (
                                  <div className="container">
                                    <div className="queststyle rightanswer">
                                      <h3>Remember</h3>
                                      <div className='behore-image'>
                                        {question.before_remember_image && (
                                          <>
                                            <IconButton aria-label="Preview" className='image-preview-icon-left' onClick={() => PreviewImage(question.before_remember_image)}>
                                              <FullscreenIcon />
                                            </IconButton>
                                            <img className='ans-desc-image' src={question.before_remember_image} />
                                          </>
                                        )}
                                      </div>
                                      <p>
                                        <MathJax>
                                          {question.remember_text && (ReactHtmlParser(question.remember_text, { transform })
                                          )}
                                        </MathJax>
                                      </p>
                                      <p>
                                        {question.remember_image && (
                                          <>
                                            <IconButton aria-label="Preview" className='image-preview-icon-left' onClick={() => PreviewImage(question.remember_image)}>
                                              <FullscreenIcon />
                                            </IconButton>
                                            <img className='ans-desc-image' src={question.remember_image} />
                                          </>
                                        )}
                                      </p>
                                      <p>
                                        {question.remember_video_link && (
                                          <Button target='_blank;' href={question.remember_video_link} variant="contained" color="success">Youtube Link</Button>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </>

                            )}
                            {answerOpen && (
                              <>
                                {(question.description_text || question.description_image || question.description_video_link) && (
                                  <div className="container">
                                    <div className="queststyle rightanswer">
                                      <h3>Answer</h3>
                                      <div className='behore-image'>
                                        {question.before_description_image && (
                                          <>
                                            <IconButton aria-label="Preview" className='image-preview-icon-left' onClick={() => PreviewImage(question.before_description_image)}>
                                              <FullscreenIcon />
                                            </IconButton>
                                            <img className='ans-desc-image' src={question.before_description_image} />
                                          </>
                                        )}
                                      </div>
                                      <p>
                                        <MathJax>
                                          {question.description_text && (ReactHtmlParser(question.description_text, { transform })
                                          )}
                                        </MathJax>
                                      </p>
                                      <p>
                                        {question.description_image && (
                                          <>
                                            <IconButton aria-label="Preview" className='image-preview-icon-left' onClick={() => PreviewImage(question.description_image)}>
                                              <FullscreenIcon />
                                            </IconButton>
                                            <img className='ans-desc-image' src={question.description_image} />
                                          </>
                                        )}
                                      </p>
                                      <p>
                                        {question.description_video_link && (
                                          <Button target='_blank;' href={question.description_video_link} variant="contained" color="success">Youtube Link</Button>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}

                          </>
                        )}
                      </>
                    )}

                  </div>
                </form>
              </div>
              <div className="col-md-3">
                {/* <div className="timer">
                  {Math.floor(countdown.expirityTime / 60)}:
                  {countdown.expirityTime % 60}
                </div> */}
                <ul className="quizstep pyp-numbers">
                  {quizQuestions && (
                    <>
                      {
                        quizQuestions.map((row, index) => (
                          <li onClick={() => handleQuestionChange(index)}
                            className={(activeQuestion === index) ? "crent" : (
                              (is_question_attempted(row.id)) ? 'comp' : ''
                            )}
                            key={index}
                            style={{ cursor: 'pointer' }}
                          >{index + 1}</li>
                        ))}
                    </>
                  )}
                </ul>
                <br /><br />
                {quizQuestions && (
                  <Button fullWidth size='small' variant="contained" className="lightbtn"
                    style={{ margin: '0px !important' }}
                    onClick={handleFinishQuiz}
                  >Finish Quiz</Button>
                )}
              </div>
            </div>
          </div>
        </section >
      </main >
      <ClearIcon sx={{ float: 'right', paddingBottom: '10px' }}></ClearIcon>
      <EditIcon sx={{ float: 'right', paddingBottom: '10px' }}></EditIcon>

      <Footer />
    </>
  );
}
export default ExamPreviousYearQuestionQuiz;
