import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import Header from './../Header/Header';
import Footer from './../Footer/Footer';
import * as Yup from 'yup';
import {
  Alert, Backdrop, CircularProgress, Grid, Button
} from '@mui/material';
import axios from 'axios';

const ExamGroupDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [exam_category_slug, setExamCategorySlug] = useState(params.exam_category_slug);
  const [exam_group_slug, setExamGroupSlug] = useState(params.exam_group_slug);
  const [examCategory, setExamCategory] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exam_group, setExamGroup] = useState(false);
  const [tableData, setTableData] = useState(false);
  useEffect(() => {
    fetchData()
  }, []);

  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/frontend/get-exam-category', {
          exam_category_slug: exam_category_slug,
        })
          .then(response => {
            if (response.data.response_code === 200) {
              setExamCategory(response.data.data)
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
        axios.post('/api/frontend/get-exam-group-exams', {
          exam_category_slug: exam_category_slug,
          exam_group_slug: exam_group_slug
        })
          .then(response => {
            if (response.data.response_code === 200) {
              setExamGroup(response.data.data.exam_group)
              setTableData(response.data.data.exams);
            } else if (response.data.response_code === 401) {

            } else {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  const classLayoutUrl = (row) => {
    var url = '';
    if (row.is_test_series || row.is_pyp) {
      url = `/${exam_category_slug}/${exam_group.slug}/exam/includes/${row.slug}`;
    } else {
      url = `/${exam_category_slug}/${exam_group.slug}/exam/subjects/${row.slug}`;
    }
    return url;
  }

  return (
    <>
      <Header />
      <Backdrop
        sx={{ color: '#015998', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={setLoading}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
      {(exam_group) ? (
        <>
          <section id="hero" className="d-flex align-items-center inhead">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center" data-aos="fade-up" data-aos-delay="200">
                  <h1>{exam_group.name}</h1>
                </div>
              </div>
            </div>
          </section>
          <main id="main">
            <section id="breadcrumbs" className="breadcrumbs">
              <div className="container">
                <ol>
                  <li><Link to="/" title='Home'>Home</Link></li>
                  {examCategory && (
                    <li><Link to={`/${examCategory.slug}`} title={examCategory.name}>{examCategory.name}</Link></li>
                  )}
                  <li>{exam_group.name}</li>
                </ol>
              </div>
            </section>
            {tableData && (
              <section className="onecol listing-page">
                <div className="container" data-aos="fade-up">
                  <Grid container spacing={3}>
                    {
                      tableData.map((row, index) => (
                        <Grid item sm={6} xs={6} lg={4} key={index}>
                          <Link to={classLayoutUrl(row)} title={row.name}>
                            <div className="boximg">
                              <span className='box-title'>{row.name}</span>
                              <div className="img-box">
                                {(row.thumb_nail_image) ? (
                                  <img src={row.thumb_nail_image} title={row.name} alt={row.name} className='class-thumb' />
                                ) : (
                                  <img title={row.name} alt={row.name} src={`${process.env.PUBLIC_URL}/assets/frontend/img/jee.jpg`} className='class-thumb' />
                                )}
                              </div>
                              <p className="lightred">{row.description}</p>
                            </div>
                          </Link>
                        </Grid>
                      ))
                    }
                  </Grid>
                </div>
              </section>
            )}
          </main >
        </>
      ) : (
        <>
          <section id="hero" className="d-flex align-items-center inhead">
            <div className="container">
            </div>
          </section>
          {(!loading) && (
            <main id="main">
              <section className="onecol listing-page">
                <div className="container" data-aos="fade-up">
                  <h1 style={{ fontSize: '125px', color: '#3c0a8d', fontFamily: 'inherit', fontWeight: '600' }}>404 Page</h1>
                  <p style={{ fontSize: '20px' }}>We con`t find what you are looking for.</p>
                  <Link to={'/'} className="lightbtn" title='Go to Home Page'>Go to Home Page</Link>
                </div>
              </section>
            </main >
          )}
        </>
      )}
      <Footer />
    </>
  );
};

export default ExamGroupDetails;
