import { useState, useEffect } from "react";
import { Link, useParams, NavLink, useNavigate } from 'react-router-dom';
import { Breadcrumb, SimpleCard } from "app/components";
import { LoadingButton } from '@mui/lab';
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import swal from 'sweetalert';
import {
  Avatar,
  Card,
  Divider,
  CardContent,
  CardHeader,
  Box,
  Grid,
  Icon,
  IconButton,
  Button,
  styled,
  TextField,
  useTheme,
  RadioGroup, FormControlLabel, Radio

} from "@mui/material";


const Item = styled("div")(({ theme }) => (
  {
    marginBottom: "5px",
    fontSize: '16px',
    fontWeight: '600',
    color: '#484747'
  }
));
const ItemContent = styled("div")(({ theme }) => (
  {
    marginBottom: "15px",
    fontSize: '16px',
    fontWeight: '400',
  }
));

const Container = styled("div")(({ theme }) => (
  {
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }
));
const ContentBox = styled(Box)(() => ({
  height: '100%',
  padding: '32px',
  position: 'relative',
  // background: 'rgba(0, 0, 0, 0.01)',
}));

// form field validation schema
const validationSchema = Yup.object().shape({
  // status: Yup.string()
  //   .required('User Accton is required!'),
  status: Yup.string().when('status', {
    is: (status) => status === 'pending',
    then: Yup.string().required('Please checked Approved or Rejected action!'),
    otherwise: Yup.string(),
  }),
}, [['status', 'status']]);

const MyProfile = () => {

  const { user_id } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [user, setUser] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      // await axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post('/api/dashboard/user/my-profile')
        .then(response => {
          if (response.data.response_code === 200) {
            setUser(response.data.data.user);
          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.error(error);
        });
      // });
    } catch (e) {
      setLoading(false);
    }
  }


  return (
    <>
      <Container>
        <Box className="breadcrumb">
          <Breadcrumb routeSegments={[{ name: "dashboard", path: "/dashboard" }, { name: "My Profile" }]} />
        </Box>
        <Card>
          <ContentBox>
            <CardHeader
              title="My Profile"
              titleTypographyProps={{
                variant: "h4",
                fontSize: '18px',
                fontWeight: '600',
                // textTransform: 'uppercase',
                color: theme.palette.primary.main
              }}
              action={
                <>
                  <Button title="Edit" component={Link}
                    to={`/dashboard/profile/edit`}
                    color="info" variant="outlined" size="small" sx={{ m: .5 }}>
                    Edit
                  </Button>
                  <Button title="Back" component={Link}
                    to={`/dashboard`}
                    color="primary" variant="outlined" size="small" sx={{ m: .5 }}>
                    Back
                  </Button>
                </>
              }
            />
            <Divider />
            <CardContent>
              <Box width="100%" overflow="auto">
                {user && (
                  <>
                    <Grid container>
                      <Grid item sm={6} xs={12} lg={3}>
                        <Item>First Name</Item>
                        <ItemContent>{(user.first_name) ? user.first_name : '-'}</ItemContent>
                      </Grid>
                      <Grid item sm={6} xs={12} lg={3}>
                        <Item>Last Name</Item>
                        <ItemContent>{(user.last_name) ? user.last_name : '-'}</ItemContent>
                      </Grid>
                      <Grid item sm={6} xs={12} lg={3}>
                        <Item>Email</Item>
                        <ItemContent>{(user.email) ? user.email : '-'}</ItemContent>
                      </Grid>
                      <Grid item sm={6} xs={12} lg={3}>
                        <Item>Mobile No.</Item>
                        <ItemContent>{(user.mobile_no) ? user.mobile_no : '-'}</ItemContent>
                      </Grid>
                      <Grid item sm={6} xs={12} lg={3}>
                        <Item>Medium Code</Item>
                        <ItemContent>{(user.medium_code) ? user.medium_code : '-'}</ItemContent>
                      </Grid>
                      <Grid item sm={6} xs={12} lg={3}>
                        <Item>Medium Name</Item>
                        <ItemContent>{(user.medium_name) ? user.medium_name : '-'}</ItemContent>
                      </Grid>
                      <Grid item sm={6} xs={12} lg={3}>
                        <Item>Pin Code</Item>
                        <ItemContent>{(user.pin_code) ? user.pin_code : '-'}</ItemContent>
                      </Grid>
                      <Grid item sm={6} xs={12} lg={3}>
                        <Item>User From</Item>
                        <ItemContent>{(user.user_from) ? user.user_from : '-'}</ItemContent>
                      </Grid>
                    </Grid>
                    <Divider />
                    <br />
                    <Grid container>
                      <Grid item sm={6} xs={12} lg={3}>
                        <Item>Course Type</Item>
                        <ItemContent style={{ textTransform: 'capitalize' }}>{(user.course) ? user.course.replace("-", ' ') : '-'}</ItemContent>
                      </Grid>
                      {(() => {
                        switch (user.course) {
                          case 'class':
                            return (
                              <>
                                <Grid item sm={6} xs={12} lg={3}>
                                  <Item>Board</Item>
                                  <ItemContent>{user.board_name}</ItemContent>
                                </Grid>
                                <Grid item sm={6} xs={12} lg={3}>
                                  <Item>Class</Item>
                                  <ItemContent>{user.class_name}</ItemContent>
                                </Grid>
                              </>
                            )
                          case 'central-exam':
                            return (
                              <>
                                <Grid item sm={6} xs={12} lg={3}>
                                  <Item>Exam</Item>
                                  <ItemContent>{user.exam_name}</ItemContent>
                                </Grid>
                              </>
                            )
                          case 'state-exam':
                            return (
                              <>
                                <Grid item sm={6} xs={12} lg={3}>
                                  <Item>State</Item>
                                  <ItemContent>{user.state_name}</ItemContent>
                                </Grid>
                                <Grid item sm={6} xs={12} lg={3}>
                                  <Item>Exam</Item>
                                  <ItemContent>{user.exam_name}</ItemContent>
                                </Grid>

                              </>
                            )
                          default:
                            return ''
                        }
                      })()}
                    </Grid>
                    <Divider />
                    <br />
                    <Grid container>
                      <Grid item sm={6} xs={12} lg={3}>
                        <Item>Profile Image</Item>
                        <ItemContent>{(user.profile_image) ? (
                          <>
                            <img src={user.profile_image} alt="Profile Image" style={{ width: '100px' }} />
                          </>
                        ) : <><Avatar sx={{ Width: '100px' }} /></>}</ItemContent>
                      </Grid>
                    </Grid>

                  </>
                )}
              </Box>
            </CardContent>
          </ContentBox>
        </Card>

      </Container>
    </>

  );
};

export default MyProfile;
