import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../../auth/authRoles';

const Classes = Loadable(lazy(() => import('./Classes')));
const ClassGroupDetails = Loadable(lazy(() => import('./ClassGroupDetails')));
const ClassIncludes = Loadable(lazy(() => import('./ClassIncludes')));
const ClassDetails = Loadable(lazy(() => import('./ClassDetails')));
const TestSeriseDetails = Loadable(lazy(() => import('./TestSeriseDetails')));
const PreviousYearPaperDetails = Loadable(lazy(() => import('./PreviousYearPaperDetails')));


const ChapterQuiz = Loadable(lazy(() => import('../ClassesQuiz/ChapterQuiz')));
const PracticeTestQuiz = Loadable(lazy(() => import('../ClassesQuiz/PracticeTestQuiz')));
const PreviousYearQuestionQuiz = Loadable(lazy(() => import('../ClassesQuiz/PreviousYearQuestionQuiz')));
const TestSeriesQuiz = Loadable(lazy(() => import('../ClassesQuiz/TestSeriesQuiz')));
const PreviousYearPaperQuiz = Loadable(lazy(() => import('../ClassesQuiz/PreviousYearPaperQuiz')));

const QuizResult = Loadable(lazy(() => import('../ClassesQuiz/QuizResult')));

const classesRoutes = [
  { path: '/dashboard/classes/', element: <Classes /> },
  // { path: '/kindergarten/', element: <Kindergarten /> },
  { path: '/dashboard/:class_group_slug', element: <ClassGroupDetails /> },
  { path: '/dashboard/:class_group_slug/class/subjects/:slug/', element: <ClassDetails /> },
  { path: '/dashboard/:class_group_slug/class/subjects/:slug/:subject_id', element: <ClassDetails /> },
  { path: '/dashboard/:class_group_slug/class/includes/:slug', element: <ClassIncludes /> },
  { path: '/dashboard/:class_group_slug/class/test-series/:slug/', element: <TestSeriseDetails /> },
  { path: '/dashboard/:class_group_slug/class/pyps/:slug/', element: <PreviousYearPaperDetails /> },

  // // Class Quiz Route
  { path: '/dashboard/class/chapter-quiz/:quiz_key', element: <ChapterQuiz /> },
  { path: '/dashboard/class/practice-test-quiz/:quiz_key', element: <PracticeTestQuiz /> },
  { path: '/dashboard/class/previous-year-question-quiz/:quiz_key', element: <PreviousYearQuestionQuiz /> },
  { path: '/dashboard/class/test-series-quiz/:quiz_key', element: <TestSeriesQuiz /> },
  { path: '/dashboard/class/previous-year-paper-quiz/:quiz_key', element: <PreviousYearPaperQuiz /> },
  { path: '/dashboard/class/quiz-result/:quiz_key', element: <QuizResult /> },
];

export default classesRoutes;
