import React, { useEffect, useState, SyntheticEvent, useRef } from 'react';
import { Link, useParams, NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from './../../../contexts/AuthContext';
import Header from './../Header/Header';
import Footer from './../Footer/Footer';
import { Carousel } from 'primereact/carousel';
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
import swal from 'sweetalert';
import * as Yup from 'yup';
import {
  Alert, Backdrop, CircularProgress,
} from '@mui/material';
import { useMedium } from 'app/contexts/MediumContext';
import axios from 'axios';
import MediumDialog from './MediumDialog';
import $ from 'jquery';
// form field validation schema
const validationSchema = Yup.object().shape({
  quantity: Yup.string()
    .required('Quantity is required!'),
});

function ClassDetails() {
  const scollToRef = useRef();
  const params = useParams();
  const navigate = useNavigate();
  const { user, authSignOut, isAuthenticated, setOpenAuth, setIsSignUp } = useAuth();
  const [loading, setLoading] = useState(false);
  const [slug, setSlug] = useState(params.slug);
  const [class_group_slug, setClassGroupSlug] = useState(params.class_group_slug);
  const [class_exam_id, setClassExamId] = useState(params.row_id);
  const [subjects, setSubjects] = useState(false);
  const [classDetail, setClassDetail] = useState(false);
  const [chapters, setChapters] = useState(false);
  const [practiceTests, setPracticeTests] = useState(false);
  const [pyqs, setPyqs] = useState(false);
  const [selected_subject, setSelectedSubject] = useState((params.subject_id) ? params.subject_id : false);

  const [selected_tab, setSelectedTab] = useState('chapter');
  const { userMedium } = useMedium();
  const [open, setOpen] = useState((!userMedium) ? false : true);
  /**
   * Effect Handler on slug and navigation change 
   */
  useEffect(() => {
    if (userMedium) {
      fetchData(slug);
    }
  }, []);
  /**
   * Effect Handler on slug and navigation change 
   */
  useEffect(() => {
    if (userMedium) {
      if (selected_subject) {
        setSelectedSubject(false);
      }
      setClassGroupSlug(params.class_group_slug);
      setSlug(params.slug);
      setClassDetail(false);
      setSubjects(false);
      setChapters(false);
      setPracticeTests(false);
      setPyqs(false);
      fetchData(params.slug);
    }
  }, [params.class_group_slug, params.slug]);

  /**
   * Load data on medium change
   */
  useEffect(() => {
    if (userMedium) {
      fetchData(slug);
      setOpen(false);
    } else {
      setOpen(true);
    }

  }, [userMedium]);

  useEffect(() => {
    if (selected_subject || (params.subject_id !== selected_subject)) {
      navigate(`/${class_group_slug}/class/subjects/${slug}/${selected_subject}`, { replace: true });
    }
  }, [selected_subject]);

  /**
   * Fetching data from server.
   */
  const fetchData = async (cur_slug) => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/frontend/get-class-details', {
          'slug': (cur_slug) ? cur_slug : slug,
          'medium_code': userMedium,
          'class_exam_id': class_exam_id
        })
          .then(response => {
            if (response.data.response_code === 200) {
              var class_exam_id = response.data.data.classDetail.id;
              setClassExamId(class_exam_id);
              if (response.data.data.subjects[0]) {
                var selected_subject_id = 0;
                if (!params.subject_id || (params.subject_id === undefined)) {
                  selected_subject_id = response.data.data.subjects[0].subject_id;
                  setSelectedSubject(selected_subject_id);
                } else {
                  if (!selected_subject || (selected_subject === undefined)) {
                    selected_subject_id = response.data.data.subjects[0].subject_id;
                    setSelectedSubject(selected_subject_id);
                  } else {
                    selected_subject_id = selected_subject;
                  }
                }
                _fn_get_subject_chapters(class_exam_id, selected_subject_id)
              }
              setSubjects(response.data.data.subjects);
              setClassDetail(response.data.data.classDetail);

            } else if (response.data.response_code === 401) {

            } else {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * This function is used to get Chapter list of selected Class and Subjects.
   * @param {*} class_exam_id 
   * @param {*} selected_subject_id 
   */
  const _fn_get_subject_chapters = async (class_exam_id, selected_subject_id) => {
    if (selected_subject !== selected_subject_id) {
      setSelectedSubject(selected_subject_id);
    }
    setSelectedTab('chapter')
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/frontend/get-class-subject-chapters', { 'class_exam_id': class_exam_id, 'selected_subject_id': selected_subject_id })
          .then(response => {
            if (response.data.response_code === 200) {
              setChapters(response.data.data);
            } else if (response.data.response_code === 401) {

            } else {

            }
            if (window.innerWidth <= 768) {
              window.scrollTo(0, 750);
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * This function is used to get Practics list of selected Class and Subjects.
   * @param {*} class_exam_id 
   * @param {*} selected_subject_id 
   */
  const _fn_get_subject_practics = async (class_exam_id, selected_subject_id) => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/frontend/get-class-subject-practice-test', { 'class_exam_id': class_exam_id, 'selected_subject_id': selected_subject_id })
          .then(response => {
            if (response.data.response_code === 200) {
              setPracticeTests(response.data.data);
            } else if (response.data.response_code === 401) {

            } else {

            }
            if (window.innerWidth <= 768) {
              window.scrollTo(0, 750);
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * This function is used to get Previous year papers list of selected Class and Subjects.
   * @param {*} class_exam_id 
   * @param {*} selected_subject_id 
   */
  const _fn_get_subject_pyps = async (class_exam_id, selected_subject_id) => {

    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/frontend/get-class-subject-pyqs', { 'class_exam_id': class_exam_id, 'selected_subject_id': selected_subject_id })
          .then(response => {
            if (response.data.response_code === 200) {
              setPyqs(response.data.data);
            } else if (response.data.response_code === 401) {

            } else {

            }
            if (window.innerWidth <= 768) {
              window.scrollTo(0, 750);
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * This function is used to Create or Start Chapter Quiz.
   * @param {*} chapter_id 
   */
  const _hendelChaperQuizStart = async (chapter_id) => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        var api_url = '/api/frontend/create-subject-chapter-quiz';
        if (isAuthenticated) {
          api_url = '/api/frontend/auth/create-subject-chapter-quiz';
        }
        axios.post(api_url, {
          'chapter_id': chapter_id,
          'class_exam_id': class_exam_id,
          'selected_subject_id': selected_subject,
          user_medium: userMedium
        })
          .then(response => {
            if (response.data.response_code === 200) {
              if (response.data.data.quiz_key) {
                navigate(`/class/chapter-quiz/${response.data.data.quiz_key}`);
              }
            } else if (response.data.response_code === 403) {
              // Open Login popup with message
              swal({
                text: response.data.message,
                icon: "info",
                buttons: [
                  'Cancel',
                  'Login'
                ],
                // timer: 2500
              }).then(function (isConfirm) {
                handleOpen(false);
              });
            } else {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * This function is used to Create or Start Practice Quiz.
   * @param {*} practice_id 
   */
  const _hendelPracticeQuizStart = async (practice_id) => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        var api_url = '/api/frontend/create-subject-practice-test-quiz';
        if (isAuthenticated) {
          api_url = '/api/frontend/auth/create-subject-practice-test-quiz';
        }
        axios.post(api_url, {
          'practice_id': practice_id,
          'class_exam_id': class_exam_id,
          'selected_subject_id': selected_subject,
          user_medium: userMedium
        })
          .then(response => {
            if (response.data.response_code === 200) {
              if (response.data.data.quiz_key) {
                navigate(`/class/practice-test-quiz/${response.data.data.quiz_key}`);
              }
            } else if (response.data.response_code === 403) {
              // Open Login popup with message
              swal({
                text: response.data.message,
                icon: "info",
                buttons: [
                  'Cancel',
                  'Login'
                ],
                // timer: 2500
              }).then(function (isConfirm) {
                handleOpen(false);
              });
            } else {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }


  /**
   * This function is used to Create or Start Chapter Quiz.
   * @param {*} previous_year_paper_id 
   */
  const _hendelPYQQuizStart = async (previous_year_question_id) => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        var api_url = '/api/frontend/create-subject-pyq-quiz';
        if (isAuthenticated) {
          api_url = '/api/frontend/auth/create-subject-pyq-quiz';
        }
        axios.post(api_url, {
          'previous_year_question_id': previous_year_question_id,
          'class_exam_id': class_exam_id,
          'selected_subject_id': selected_subject,
          user_medium: userMedium
        })
          .then(response => {
            if (response.data.response_code === 200) {
              if (response.data.data.quiz_key) {
                navigate(`/class/previous-year-question-quiz/${response.data.data.quiz_key}`);
              }
            } else if (response.data.response_code === 403) {
              // Open Login popup with message
              swal({
                text: response.data.message,
                icon: "info",
                buttons: [
                  'Cancel',
                  'Login'
                ],
                // timer: 2500
              }).then(function (isConfirm) {
                handleOpen(false);
              });
            } else {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * Handle login auth
   * @param {*} authState 
   */
  const handleOpen = (authState) => {
    if (authState) {
      setIsSignUp(true);
    } else {
      setIsSignUp(false);
    }
    setOpenAuth(true);
  }

  function _fn_getSelectedSubjectName() {
    if (subjects && subjects.find((row) => row.subject_id === parseInt(selected_subject)) !== undefined) {
      const subjectRow = subjects.filter((row) => row.subject_id === parseInt(selected_subject));
      return subjectRow[0].subject_name;
      // return (subjectRow[0].subject_name) ? subjectRow[0].subject_name.replace(/(.{15})..+/, "$1…") : subjectRow[0].subject_name;
    } else {
      return '';
    }
  }

  // selected_subject_name
  const subjectTemplate = (row) => {
    return (
      <div className="boximg" key={'index'}>
        <Link to={"#"} onClick={() => _fn_get_subject_chapters(class_exam_id, row.subject_id)}>
          <span className='box-title'>{(row.subject_name) ? row.subject_name.replace(/(.{15})..+/, "$1…") : row.subject_name}</span>
          <div className={(parseInt(selected_subject) === parseInt(row.subject_id)) ? "img-box current" : "img-box"}>
            {(row.subject_image) ? (
              <img src={row.subject_image} title={row.subject_name} alt={row.subject_name} style={{ maxHeight: '312px' }} />
            ) : (
              (row.subject_thumb_nail_image) ? (
                <img src={row.subject_thumb_nail_image} title={row.subject_name} alt={row.subject_name} style={{ maxHeight: '312px' }} />
              ) : (
                <img title={row.subject_name} alt={row.subject_name} style={{ maxHeight: '312px' }} src={`${process.env.PUBLIC_URL}/assets/frontend/img/physics.jpg`} />
              )
            )
            }
          </div>
        </Link>
      </div>
    );
  };


  return (
    <>
      <Header slug={slug} setSlug={setSlug} />
      <Backdrop
        sx={{ color: '#015998', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={setLoading}
      >
        <CircularProgress color="secondary" />
      </Backdrop>

      <MediumDialog open={open} />


      <section id="hero" className="d-flex align-items-center inhead">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center" data-aos="fade-up" data-aos-delay="200">
              {/* <h1>{(classDetail) ? classDetail.class_name : ''} Subject:
                {(selected_subject) ? ` ${_fn_getSelectedSubjectName()}` : " All"}</h1> */}
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <ol>
              <li><Link to="/" title='Home'>Home</Link></li>
              {classDetail && (
                <li>{(classDetail) ? classDetail.class_name : ''}</li>
              )}
              {(selected_subject) && (
                <li>{_fn_getSelectedSubjectName()}</li>
              )}
            </ol>
          </div>
        </section>

        <section className="inner-page listing-page">
          <div className="container">
            <div className="row">
              <div className="col-md-3 text-center leftinrimg">
                {subjects && (
                  <>
                    {(subjects.length) ? <Carousel
                      // responsiveOptions={responsiveOptions}
                      value={subjects}
                      numVisible={3} numScroll={1}
                      orientation="vertical"
                      verticalViewPortHeight="580px"
                      itemTemplate={subjectTemplate} />
                      :
                      <Alert severity="error" style={{ textAlign: 'center' }}>Subject list not founds!</Alert>
                    }

                    {/* {
                      subjects.map((row, index) => (
                        <div className="boximg" key={index}>
                          <Link to={"#"} onClick={() => _fn_get_subject_chapters(class_exam_id, row.subject_id)}>
                            <span className='box-title'>{selected_subject}{(parseInt(selected_subject) === parseInt(row.subject_id)) ? "img-box current" : "img-box"} {row.subject_id} {row.subject_name}</span>
                            <div className={(parseInt(selected_subject) === parseInt(row.subject_id)) ? "img-box current" : "img-box"}>
                              {(row.subject_image) ? (
                                <img src={row.subject_image} title={row.subject_name} alt={row.subject_name} style={{ maxHeight: '312px' }} />
                              ) : (
                                (row.subject_thumb_nail_image) ? (
                                  <img src={row.subject_thumb_nail_image} title={row.subject_name} alt={row.subject_name} style={{ maxHeight: '312px' }} />
                                ) : (
                                  <img title={row.subject_name} alt={row.subject_name} style={{ maxHeight: '312px' }} src={`${process.env.PUBLIC_URL}/assets/frontend/img/physics.jpg`} />
                                )
                              )
                              }
                            </div>
                          </Link>
                        </div>
                      )
                      )
                    } */}
                  </>
                )}
              </div>

              <div className="col-md-9">
                <div className="tabdesign" ref={scollToRef}>
                  <nav>
                    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                      <button className={(selected_tab === 'chapter') ? 'nav-link active' : 'nav-link'}
                        id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home"
                        aria-selected="true"
                        onClick={e => {
                          setSelectedTab('chapter');
                          _fn_get_subject_chapters(class_exam_id, selected_subject);
                        }}
                      >
                        <i className="bi bi-book"></i>
                        Chapter Wise
                      </button>
                      {classDetail &&
                        (
                          <>
                            {(classDetail.is_practice_test) ? (
                              //  mr-10
                              <button className={(selected_tab === 'practice') ? 'nav-link ml-10 active' : 'nav-link ml-10'} id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false"
                                onClick={e => {
                                  setSelectedTab('practice');
                                  _fn_get_subject_practics(class_exam_id, selected_subject);
                                }}
                              >
                                <i className="bi bi-pencil-square"></i>
                                Practice Tests
                              </button>

                            ) : ''}
                            {(classDetail.is_pyq) ? (
                              <button className={(selected_tab === 'pyp') ? 'nav-link ml-10 active' : 'nav-link ml-10'} id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false"
                                onClick={e => {
                                  setSelectedTab('pyp');
                                  _fn_get_subject_pyps(class_exam_id, selected_subject);
                                }}
                              >
                                <i className="bi bi-file-earmark-text"></i>
                                Previous year<br />question paper
                              </button>
                            ) : ''}
                          </>

                        )}
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div className={(selected_tab === 'chapter') ? 'tab-pane fade show active' : 'tab-pane fade'} id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      {chapters && (chapters.length) ? (
                        <ul>
                          {
                            chapters.map((row, index) => (
                              <li key={`chapter-${index}`}>
                                <Link onClick={() => _hendelChaperQuizStart(row.chapter_id)} to={'#'} title={row.chapter_name}>
                                  <span>{row.chapter_name}</span>
                                  {row.description}
                                  <i className="bi bi-arrow-right"></i>
                                </Link>
                              </li>
                            )
                            )
                          }
                        </ul>
                      ) : (
                        <>
                          {chapters && (
                            <Alert severity="error" style={{ textAlign: 'center' }}>No Chapters found in this subject — check it out!</Alert>
                          )}
                        </>
                      )}
                    </div>

                    <div className={(selected_tab === 'practice') ? 'tab-pane fade show active' : 'tab-pane fade'} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                      {practiceTests && (practiceTests.length) ? (
                        <ul>
                          {
                            practiceTests.map((row, index) => (
                              <li key={`practice-${index}`}>
                                <Link onClick={() => _hendelPracticeQuizStart(row.practice_id)} to={'#'} title={row.practice_test_name}>
                                  <span>{row.practice_test_name}</span>
                                  {row.description}
                                  <i className="bi bi-arrow-right"></i>
                                </Link>
                              </li>
                            )
                            )
                          }
                        </ul>
                      ) : (
                        <>
                          {practiceTests && (
                            <Alert severity="error" style={{ textAlign: 'center' }}>No Practice Tests found in this subject — check it out!</Alert>
                          )}
                        </>
                      )}

                    </div>
                    <div className={(selected_tab === 'pyp') ? 'tab-pane fade show active' : 'tab-pane fade'} id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                      {pyqs && (pyqs.length) ? (
                        <ul>
                          {
                            pyqs.map((row, index) => (
                              <li key={`pyqs-${index}`}>
                                <Link onClick={() => _hendelPYQQuizStart(row.previous_year_question_id)} to={'#'} title={row.pyp_name}>
                                  <span>{row.pyp_name}</span>
                                  {row.description}
                                  <i className="bi bi-arrow-right"></i>
                                </Link>
                              </li>
                            )
                            )
                          }
                        </ul>
                      ) : (
                        <>
                          {pyqs && (
                            <Alert severity="error" style={{ textAlign: 'center' }}>No Previous Year paper found in this subject — check it out!</Alert>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section >

      </main >


      <Footer />
    </>
  );
}
export default ClassDetails;
