import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../../auth/authRoles';

const TodayQuiz = Loadable(lazy(() => import('./TodayQuiz')));
const AllQuiz = Loadable(lazy(() => import('./AllQuiz')));

const quizHistoryRoutes = [
  { path: '/dashboard/quiz-history/today-quiz', element: <TodayQuiz />, auth: authRoles.admin },
  { path: '/dashboard/quiz-history/all-quiz', element: <AllQuiz />, auth: authRoles.admin },
];

export default quizHistoryRoutes;
