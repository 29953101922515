import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../../auth/authRoles';

const StateExams = Loadable(lazy(() => import('./StateExams')));

const ExamGroupDetails = Loadable(lazy(() => import('./ExamGroupDetails')));
const ExamIncludes = Loadable(lazy(() => import('./ExamIncludes')));
const ExamDetails = Loadable(lazy(() => import('./ExamDetails')));
const ExamTestSeriseDetails = Loadable(lazy(() => import('./ExamTestSeriseDetails')));
const ExamPreviousYearPaperDetails = Loadable(lazy(() => import('./ExamPreviousYearPaperDetails')));


const ExamChapterQuiz = Loadable(lazy(() => import('../ExamsQuiz/ExamChapterQuiz')));
const ExamPracticeTestQuiz = Loadable(lazy(() => import('../ExamsQuiz/ExamPracticeTestQuiz')));
const ExamPreviousYearQuestionQuiz = Loadable(lazy(() => import('../ExamsQuiz/ExamPreviousYearQuestionQuiz')));
const ExamTestSeriesQuiz = Loadable(lazy(() => import('../ExamsQuiz/ExamTestSeriesQuiz')));
const ExamPreviousYearPaperQuiz = Loadable(lazy(() => import('../ExamsQuiz/ExamPreviousYearPaperQuiz')));

const ExamQuizResult = Loadable(lazy(() => import('../ExamsQuiz/ExamQuizResult')));

const examsRoutes = [
  { path: '/dashboard/state-exams', element: <StateExams /> },
  { path: '/dashboard/:exam_category_slug/exam/includes/:slug', element: <ExamIncludes /> },
  { path: '/dashboard/:exam_category_slug/exam/subjects/:slug/', element: <ExamDetails /> },
  { path: '/dashboard/:exam_category_slug/exam/subjects/:slug/:subject_id', element: <ExamDetails /> },
  { path: '/dashboard/:exam_category_slug/exam/test-series/:slug/', element: <ExamTestSeriseDetails /> },
  { path: '/dashboard/:exam_category_slug/exam/pyps/:slug/', element: <ExamPreviousYearPaperDetails /> },

  { path: '/dashboard/:exam_category_slug/:exam_group_slug', element: <ExamGroupDetails /> },
  { path: '/dashboard/:exam_category_slug/:exam_group_slug/exam/includes/:slug', element: <ExamIncludes /> },
  { path: '/dashboard/:exam_category_slug/:exam_group_slug/exam/subjects/:slug', element: <ExamDetails /> },
  { path: '/dashboard/:exam_category_slug/:exam_group_slug/exam/subjects/:slug/:subject_id', element: <ExamDetails /> },
  { path: '/dashboard/:exam_category_slug/:exam_group_slug/exam/test-series/:slug', element: <ExamTestSeriseDetails /> },
  { path: '/dashboard/:exam_category_slug/:exam_group_slug/exam/pyps/:slug', element: <ExamPreviousYearPaperDetails /> },

  // Exam Quiz Route
  { path: '/dashboard/exam/chapter-quiz/:quiz_key', element: <ExamChapterQuiz /> },
  { path: '/dashboard/exam/practice-test-quiz/:quiz_key', element: <ExamPracticeTestQuiz /> },
  { path: '/dashboard/exam/previous-year-question-quiz/:quiz_key', element: <ExamPreviousYearQuestionQuiz /> },
  { path: '/dashboard/exam/test-series-quiz/:quiz_key', element: <ExamTestSeriesQuiz /> },
  { path: '/dashboard/exam/previous-year-paper-quiz/:quiz_key', element: <ExamPreviousYearPaperQuiz /> },
  { path: '/dashboard/exam/quiz-result/:quiz_key', element: <ExamQuizResult /> },
];

export default examsRoutes;
